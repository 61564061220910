const stackedPiePlugin = {
  id: 'stackedPie',
  beforeDraw: (chart, args, plugins) => {
    const { ctx, data, chartArea: { bottom } } = chart;

    let minIndex = -1;
    let minValue = Number.MAX_VALUE;

    data.datasets.forEach((dataset, i) => {
      if (dataset.data[i] < minValue) {
        minValue = dataset.data[i];
        minIndex = i;
      }
    })

    data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data[i].innerRadius = 0;
      chart.getDatasetMeta(i).data[i].y = bottom - chart.getDatasetMeta(i).data[i].outerRadius;
    });

    data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i).data[i];
      const dataPoint = dataset.data[i];
      const centerX = meta.x;
      const centerY = meta.y;
      const radius = meta.outerRadius;
      const fontSize = 14;

      ctx.font = `600 ${fontSize}px Manrope`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = 'black';

      if (i === minIndex) {
        ctx.fillStyle = 'white';
        ctx.fillText(dataPoint + "M", centerX, centerY);
      } else {
        ctx.fillText(dataPoint + "M", centerX, centerY - radius + 22);
      }
    });
  },
};

export default stackedPiePlugin;
