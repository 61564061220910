import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import Chips from '../theme/Components/Chips/Chips'
import { ChipsSnippet } from '../theme/Components/Chips/CodeSnippet'

function ChipsComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <Chips />
        }
        title="Normal"
        desc="A type of input that converts the text entered by the user into a chip with a fill and bounding box. Each chip represents one input"
        code={ChipsSnippet.normal.code}
      />
    </div>)
}

export default ChipsComp