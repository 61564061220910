import React from 'react'
import DatePicker from 'react-date-picker'
import './date_picker.scss'
import { DatePickerIcon } from '../../icons'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
export const DatePickerElement = ({ value, onChange }) => {
  return (
    <div className="w-full h-[260px]">
      <DatePicker
        onChange={onChange}
        value={value}
        className={`mail-date-picker`}
        format="dd-MM-y"
        clearIcon={false}
        calendarIcon={<DatePickerIcon />}
      />
    </div>
  )
} 
