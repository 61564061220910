import React, { useState } from 'react'
import Search from '../Search/Search'
import { AccordionIcon, AddIcon, CustomIcon, HamburgerIcon, NotificationIcon, QuestionIcon } from '../../icons'
import AvatarElement from '../Avatar/Avatar';
import DropdownElement from '../Dropdown/Dropdown';
import './navbar.scss'

export const Navbar = ({ organization }) => {

  const [isExpand, setExpand] = useState(true)
  const [isActive, setActive] = useState(0)
  const NavIcon = "h-[var(--emt-space-1000)] w-[var(--emt-space-1000)] flex items-center justify-center rounded-full cursor-pointer border border-[var(--emt-background-button-stroke-primary)]"
  const NavLinks = [
    {
      key: 1,
      name: "Products",
      icon: <CustomIcon />
    },
    {
      key: 2,
      name: "Products",
      icon: <CustomIcon />
    },
    {
      key: 3,
      name: "Products",
      icon: <CustomIcon />
    },
    {
      key: 4,
      name: "Products",
      icon: <CustomIcon />
    },
    {
      key: 5,
      name: "Products",
      icon: <CustomIcon />,
      disabled: true
    },
    {
      key: 6,
      name: "Products",
      icon: <CustomIcon />
    },
    {
      key: 7,
      name: "Products",
      icon: <CustomIcon />
    },
    {
      key: 8,
      name: "Products",
      icon: <CustomIcon />
    },
    {
      key: 9,
      name: "Products",
      icon: <CustomIcon />
    },
  ];

  const DropData = [
    {
      key: "new",
      label: <div className="flex items-center gap-[var(--emt-space-200)]">
        <span className=""><img src="images/svg/navOrganization.svg" alt="" /></span>
        <span className="">Option</span>
      </div>,
    },
    {
      key: "copy",
      label: <div className="flex items-center gap-[var(--emt-space-200)]">
        <span className=""><img src="images/svg/navOrganization.svg" alt="" /></span>
        <span className="">Option</span>
      </div>,
    },
    {
      key: "edit",
      label: <div className="flex items-center gap-[var(--emt-space-200)]">
        <span className=""><img src="images/svg/navOrganization.svg" alt="" /></span>
        <span className="">Option</span>
      </div>,
    },
    {
      key: "delete",
      label: <div className="flex items-center gap-[var(--emt-space-200)]">
        <span className=""><AddIcon /></span>
        <span className="">Add option</span>
      </div>,
    }
  ];

  return (
    <div className="w-full h-screen flex flex-col">
      <div className='flex justify-between items-center w-full px-[var(--emt-space-600)] py-[var(--emt-space-300)] bg-[var(--emt-background-surface)] border-b-1 border-[var(--emt-border-primary)]'>
        <div className="flex items-center gap-[var(--emt-space-300)]">
          <span onClick={() => setExpand((prev) => !prev)} className="cursor-pointer"><HamburgerIcon /></span>
          <span className="cursor-pointer">
            <img src="/images/svg/logo-light.svg" alt="logo" />
          </span>
        </div>
        <div className="w-1/2">
          <Search keyboard />
        </div>
        <div className="flex items-center gap-[var(--emt-space-300)]">
          <span className={`${NavIcon}`}><QuestionIcon /></span>
          <span className={`${NavIcon} relative`}><NotificationIcon />
            <div className="w-[var(--emt-space-300)] h-[var(--emt-space-300)] absolute top-0 right-0 bg-[var(--emt-background-notification)] rounded-full border-3 border-white"></div>
          </span>
          <AvatarElement src="/images/svg/avatar.svg" />
        </div>
      </div>
      <div className={`w-fit ${isExpand && "min-w-[268px]"} flex gap-8 h-screen flex-col justify-between px-[var(--emt-space-600)] py-[var(--emt-space-800)]`}>
        <div className="flex flex-col gap-[var(--emt-space-800)]">
          {
            organization && <DropdownElement Items={DropData}>
              {
                isExpand ? <div className="flex organization-dropdown aria-expanded:opacity-1 justify-between aria-expanded:transform-none cursor-pointer aria-expanded:bg-[var(--emt-background-surface-hover)] p-[var(--emt-space-300)] bg-[var(--emt-background-primary)] rounded-md hover:bg-[var(--emt-background-surface-hover)] transition items-center border border-[var(--emt-border-primary)]
              ">
                  <div className="flex items-center gap-[var(--emt-space-200)]">
                    <span className=""><img className='w-[var(--emt-space-900)] h-[var(--emt-space-900)]' src="images/svg/navOrganization.svg" alt="" /></span>
                    <div className="flex flex-col gap-[var(--emt-space-50)]">
                      <h6 className="text-[var(--emt-text-tertiary)] font-medium text-sm">Label</h6>
                      <h4 className="text-[var(--emt-text-primary)] font-medium text-base">Body text</h4>
                    </div>
                  </div>
                  <span className="flex items-center justify-center rounded-full h-[var(--emt-space-800)] w-[var(--emt-space-800)] bg-[var(--emt-background-button-ghost-pressed)]">
                    <AccordionIcon />
                  </span>
                </div> : <span className="py-[var(--emt-space-300)] cursor-pointer"><img className='w-[var(--emt-space-900)] h-[var(--emt-space-900)]' src="images/svg/navOrganization.svg" alt="" /></span>
              }
            </DropdownElement>
          }
          <div className="flex flex-col gap-[var(--emt-space-500)]">
            {
              NavLinks.map((link, i) => {
                return (
                  <div key={i} onClick={() => setActive(i)} className={`flex group cursor-pointer link-container items-center ${isActive === i && "active"} ${link.disabled && "pointer-events-none"}`}>
                    <span className={`rounded-full border-1 border-[var(--emt-border-tertiary)] w-[var(--emt-space-900)] group-hover:bg-[var(--emt-background-emphasis)] group-hover:border-transparent transition icon-container h-[var(--emt-space-900)] flex justify-center items-center ${isActive === i && "!bg-[var(--emt-background-fill-selected)] border-transparent"} ${link.disabled && "disabled"}`}>
                      {link.icon}
                    </span>
                    <h6 className={`text-[var(--emt-text-tertiary)] group-hover:text-[var(--emt-text-secondary)] transition ease-in-out delay-150 font-medium text-xl capitalize max-w-0 overflow-hidden ${isExpand && "max-w-[200px] overflow-visible ml-[var(--emt-space-300)]"} ${isActive === i && "!text-[var(--emt-text-secondary)]"} ${link.disabled && "text-[var(--emt-text-disabled)]"}`}>{link.name}</h6>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* {
          settings && <div className="flex cursor-pointer items-center">
            <span className="rounded-full border-1 border-[var(--emt-border-tertiary)] w-[var(--emt-space-900)] h-[var(--emt-space-900)] flex justify-center items-center">
              <CustomIcon />
            </span>
            <h6 className={`text-[var(--emt-text-secondary)] transition-width ease-in-out delay-150 font-medium text-xl capitalize max-w-0 overflow-hidden ${isExpand && "max-w-[200px] overflow-visible ml-[var(--emt-space-300)]"}`}>Settings</h6>
          </div>
        } */}
      </div>
    </div>
  )
}
