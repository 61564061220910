export const DrawerSnippet = {
  normal: {
    key: '1',
    code: `
import React, { useState } from 'react';
import { DrawerElement,ButtonElement } from '@email-tracker';  
 
const Element = () => {
    
  const [isOpen,setOpen] = useState(false)
  
  return (
    <>
    <ButtonElement text="Open Drawer" onPress={() => setOpen(true)} />
    <DrawerElement isOpen={isOpen} setOpen={setOpen}>
      <div className="flex flex-col gap-2">
        <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
      </div>
    </DrawerElement>
  </>
  );
};
    
export default Element;
  `.trim()
  },
  large: {
    key: '2',
    code: `
import React, { useState } from 'react';
import { DrawerElement,ButtonElement } from '@email-tracker'; 
    
const Element = () => {
    
  const [isOpen,setOpen] = useState(false)
  
  return (
    <>
    <ButtonElement text="Open Drawer" onPress={() => setOpen(true)} />
    <DrawerElement size="lg" prefix isOpen={isOpen} setOpen={setOpen}>
      <div className="flex flex-col gap-2">
        <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
      </div>
    </DrawerElement>
  </>
  );
};
    
export default Element;
  `.trim()
  },
  position: {
    key: '1',
    code: `
import React, { useState } from 'react';
import { DrawerElement,ButtonElement } from '@email-tracker'; 
    
const Element = () => {
    
  const [isOpen,setOpen] = useState(false)
  
  return (
    <>
    <div className="flex flex-col gap-5">
    <RadioElement value={position} onChange={setPosition} radioData={positionData} orientation="horizontal" />
    <ButtonElement text="Open Drawer" onPress={() => toggleDrawer('drawer3')} />
   </div>
    <DrawerElement position={position} isOpen={isOpen} setOpen={setOpen}>
      <div className="flex flex-col gap-2">
        <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
      </div>
    </DrawerElement>
  </>
  );
};
    
export default Element;
  `.trim()
  },
}