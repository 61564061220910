import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import SelectElement from '../theme/Components/Select/Select'
import { SelectSnippet } from '../theme/Components/Select/CodeSnippet';

function SelectComp() {
  const SelectData = [
    {
      key: "1",
      label: "Option 1",
    },
    {
      key: "2",
      label: "Option 2",
    },
    {
      key: "3",
      label: "Option 3",
    },
    {
      key: "4",
      label: "Option 4",
    },
    {
      key: "5",
      label: "Option 5",
    },
  ];
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <SelectElement data={SelectData} />
        }
        title="Single select"
        desc="Single select gives the user a list of option to choose from, from which the use can select only one"
        code={SelectSnippet.normal.code}
      />
      <CompLayout
        component={
          <SelectElement data={SelectData} multiple />
        }
        title="Multiple select"
        desc="Multiple select gives the user a list of option to choose from, from which the use can select many"
        code={SelectSnippet.multiple.code}
      />
      <CompLayout
        component={
          <SelectElement data={SelectData} multiple prefix />
        }
        title="with prefix icon"
        desc="Select variant where the icon representing the options are at the front of the labels"
        code={SelectSnippet.prefix.code}
      />
      <CompLayout
        component={
          <SelectElement data={SelectData} multiple suffix />
        }
        title="with suffix icon"
        desc="Select variant where the icon representing the options are at the back of the labels"
        code={SelectSnippet.suffix.code}
      />
      <CompLayout
        component={
          <SelectElement data={SelectData} multiple suffix prefix />
        }
        title="with suffix and prefix icon"
        desc="Select variant that has icons representing the option both at the front and back of the labels"
        code={SelectSnippet.suffixAndPrefix.code}
      />
    </div>
  )
}

export default SelectComp