import React from 'react'
import TagElement from '../theme/Components/Tag/Tag'
import { TagSnippet } from './../theme/Components/Tag/CodeSnippet';
import CompLayout from '../theme/Layout/CompLayout';
function TagComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <TagElement>Chip comp</TagElement>
        }
        title="Normal"
        desc="Descriptive label used for categorizing content, and assigning a status"
        code={TagSnippet.normal.code}
      />
      <CompLayout
        component={
          <TagElement closable>Chip comp</TagElement>
        }
        title="Closable"
        desc="A tag that can be removed, used often for multiple entries in a single field"
        code={TagSnippet.closable.code}
      />
      <CompLayout
        component={
          <TagElement positive>Chip comp</TagElement>
        }
        title="Positive"
        desc="A tag that exhibits a positive state/favorable content."
        code={TagSnippet.closable.code}
      />
      <CompLayout
        component={
          <TagElement negative>Chip comp</TagElement>
        }
        title="Negative"
        desc="A tag that exhibits a negative state/undesirable content."
        code={TagSnippet.closable.code}
      />
    </div>
  )
}

export default TagComp