export const StepsSnippet = {
  normal: {
    key: '1',
    code:
      `
import StepsElement from '@email-tracker';

const Element = () => {

  return (
    <StepsElement />
  )

export default Element;`.trim()
  },
  dense: {
    key: '1',
    code:
      `
import StepsElement from '@email-tracker';

const Element = () => {

  return (
    <StepsElement type={'dense'}/>
  )

export default Element;`.trim()
  },
}