export const RadioSnippet = {
  normal: {
    key: '1',
    code:
      `
import RadioElement from '@email-tracker';

const Element = () => {

  const radioData = [
    {
      value: 'buenos-aires',
      label: 'Buenos Aires'
    }, 
    {
      value: 'bogota',
      label: 'Bogota'
    }
  ];

  return (
    <RadioElement radioData={radioData} />
  )

export default Element;`.trim()
  }
}