import React from 'react'
import { Chip } from "@nextui-org/react";
import { CloseIcon } from './../../icons';
function TagElement({ key = "1", children, onClose, closable, positive, negative, disabled }) {
  const PositiveTag = positive && "bg-[var(--emt-background-fill-positive-secondary)] border-[var(--emt-border-positive-secondary)] hover:!bg-[var(--emt-border-positive-secondary)]"
  const NegativeTag = negative && "bg-[var(--emt-background-fill-negative-secondary)] border-[var(--emt-border-negative-secondary)] hover:!bg-[var(--emt-background-fill-negative-secondary-hover)]"
  return (
    <Chip key={key} endContent={closable && <CloseIcon className="cursor-pointer" />} onClose={onClose} classNames={{
      base: `flex gap-[var(--emt-space-150)] h-fit items-center px-[var(--emt-space-200)] py-[var(--emt-space-50)] rounded-full bg-[var(--emt-background-fill-default)] border border-[var(--emt-border-tertiary)] ${PositiveTag} ${NegativeTag} transition  ${disabled ? "" : "cursor-pointer hover:bg-[var(--emt-background-fill-default-hover)]"}`,
      content: "font-medium text-sm text-[var(--emt-text-primary)] leading-[14.4px] p-0",
      closeButton: "opacity-1",
    }}>{children}</Chip>
  )
}

export default TagElement