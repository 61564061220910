import React from 'react'
import { NextUIProvider } from '@nextui-org/react';
import Router from './router/Index';
import './index.scss';

function App() {
    return (
        <NextUIProvider>
            <Router />
        </NextUIProvider>
    )
}

export default App