import { Select, SelectItem } from '@nextui-org/react';
import React from 'react';
import { CustomIcon, DropdownArrow } from '../../icons';
import './select.scss';

function SelectElement({
  data,
  multiple,
  required,
  id = 'select',
  className = '',
  label = 'Label',
  defaultSelectedKeys = ['5'],
  placeholder = 'Select a value',
  suffix,
  prefix,
  onChange,
}) {
  return (
    <div className="flex flex-col gap-[var(--emt-space-100)] w-[327px]">
      <label
        className="text-[var(--emt-text-secondary)] font-medium text-base leading-normal tracking-normal"
        htmlFor={id}
      >
        {label}
      </label>
      <Select
        id={id}
        isRequired={required}
        items={data}
        label={null}
        defaultSelectedKeys={defaultSelectedKeys}
        onChange={onChange}
        placeholder={placeholder}
        selectionMode={multiple ? 'multiple' : 'single'}
        selectorIcon={<DropdownArrow />}
        endContent={suffix && <CustomIcon stroke="#0C0D10" size={16} />}
        startContent={prefix && <CustomIcon stroke="#0C0D10" size={16} />}
        className={className}
        classNames={{
          base: 'mail-select',
          innerWrapper: 'gap-[var(--emt-space-200)]',
          trigger:
            'h-auto !outline-none min-h-unit-0 px-[var(--emt-space-300)] py-[var(--emt-space-250)] gap-[var(--emt-space-200)] !bg-[var(--emt-background-surface)] border-1 border-[var(--emt-border-input)] hover:border-[var(--emt-border-input-active)] transition rounded-sm',
          value: 'text-[var(--emt-text-placeholder)] leading-normal text-base',
          listboxWrapper: 'mail-select-popover',
          popoverContent:
            'p-[var(--emt-space-100)] shadow-[var(--emt-shadow-300)]  rounded-md border-1 border-[var(--emt-border-tertiary)]',
          listbox: 'p-0',
        }}
      >
        {(item) => <SelectItem key={item.key}>{item.label}</SelectItem>}
      </Select>
    </div>
  );
}

export default SelectElement;
