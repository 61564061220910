import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout';
import ButtonElement from '../../theme/Components/Button/Button';
import { ButtonSnippet } from '../../theme/Components/Button/CodeSnippet';

function LinkButton() {
  const buttonVariant = [
    { title: 'normal', desc: "The default size for links, it is used whenever it is required in the design" },
    { title: 'disabled', desc: "Indicates a CTA that is not yet active and clickable for the user", disabled: true },
  ];
  return (
    <div>
      <h3 className="section-title">Link</h3>
      <p className="comp-desc">
        Link buttons indicate the clicking will lead to an external source.
      </p>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        {buttonVariant.map(({ size, title, desc, fluid, disabled }, i) => (
          <CompLayout
            key={title}
            component={<ButtonElement size={size} variant="link" disabled={disabled} />}
            title={title}
            desc={desc}
            code={ButtonSnippet.link[i].code}
          />
        ))}
      </div>
    </div>
  )
}

export default LinkButton