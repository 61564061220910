import {
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import React, { useMemo, useState } from 'react';
import { users } from './users';
import PaginationElement from '../Pagination/Pagination';

const SelectData = [
  { key: '5', label: '5' },
  { key: '10', label: '10' },
  { key: '15', label: '15' },
  { key: '20', label: '20' },
];

const TablePagination = () => {
  const defaultRowsPerPage = parseInt(SelectData[0].key);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const pages = Math.ceil(users.length / rowsPerPage);

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return users.slice(start, end);
  }, [page, rowsPerPage]);

  const handleSelect = (e) => {
    if (e.target.value) {
      setRowsPerPage(parseInt(e.target.value));
      setPage(1);
    } else {
      setRowsPerPage(defaultRowsPerPage);
      setPage(1);
    }
  };

  const bottomContent = (
    <PaginationElement
      page={page}
      setPage={setPage}
      initialPage={page}
      total={pages}
      selectRowsPerPage={true}
      defaultSelectValue={defaultRowsPerPage.toString()}
      selectData={SelectData}
      handlePageChange={(page) => setPage(page)}
      handleRowsPerPage={handleSelect}
    />
  );

  return (
    <>
      <Table
        aria-label="Example static collection table"
        bottomContent={bottomContent}
      >
        <TableHeader>
          <TableColumn key="name">NAME</TableColumn>
          <TableColumn key="role">ROLE</TableColumn>
          <TableColumn key="status">STATUS</TableColumn>
        </TableHeader>
        <TableBody items={items}>
          {(item) => (
            <TableRow key={item.name}>
              {(columnKey) => (
                <TableCell>{getKeyValue(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default TablePagination;
