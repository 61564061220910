import React, { useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import ButtonElement from '../Button/Button';
import { CustomIcon, ModalCloseIcon } from '../../icons';
function ModalElement({ children, heading, footer = true, prefix, closable = true }) {
  const [isOpen, setOpen] = useState(false)
  const onOpenChange = (open) => setOpen(open)
  return (
    <>
      <ButtonElement onPress={() => setOpen(true)} text="Open Modal" />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={!closable}
        closeButton={<span><ModalCloseIcon width={13} height={13} /></span>}
        classNames={{
          base: "rounded-lg border-1 border-[var(--emt-border-primary)]",
          header: "py-[var(--emt-space-300)] px-[var(--emt-space-500)] bg-[var(--emt-background-surface)]",
          closeButton: "top-[12px] right-[20px] rounded-full p-[var(--emt-space-50)] w-[var(--emt-space-600)] h-[var(--emt-space-600)] transition flex justify-center items-center hover:bg-[var(--emt-background-button-default-hovered)]",
          body: "p-[var(--emt-space-600)] bg-[var(--emt-background-surface)]",
          footer: "p-[var(--emt-space-500)] gap-[var(--emt-space-400)] bg-[var(--emt-background-surface)]",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center gap-[var(--emt-space-200)]">
                {
                  prefix && <span className=""><CustomIcon stroke='#0C0D10' size='20' /></span>
                }
                <h5 className="text-base font-medium text-[var(--emt-text-primary)]">{heading}</h5>
              </ModalHeader>
              <ModalBody>
                {children}
              </ModalBody>
              <ModalFooter>
                {
                  !footer ? null : footer === true ? <>
                    <ButtonElement variant="danger" onPress={onClose} size="sm" text="Close" />
                    <ButtonElement variant="primary" onPress={onClose} size="sm" text="Action" />
                  </> : footer
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalElement