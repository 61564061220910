export const TabSnippet = {
  normal: {
    key: '1',
    code: `
import TabElement from '@email-tracker';

const Element = () => {

  let tabs = [
    {
      id: "photos",
      label: "Photos",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut "
    },
    {
      id: "music",
      label: "Music",
      content: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea "
    },
    {
      id: "videos",
      label: "Videos",
      content: "Excepteur sint occaecat cupidatat non proident,"
    }
  ];
  
  return (
    <TabElement tabData={tabs} />
  );
};
    
export default Element;
  `.trim()
  }

}