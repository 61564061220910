import React from 'react'
import { CautionAlertIcon, DangerAlertIcon, InfoAlertIcon, ModalCloseIcon, SuccessAlertIcon } from '../../icons'
import ButtonElement from './../Button/Button';

export const Alert = ({ prefix, action, closable = true, variant = "information", title = true, desc = "This is a info alert. It also has longer text to see what these alerts can look like when broken into multiple lines. This one will definitely break into multiple lines in most standard screen resolutions." }) => {

  let bgColor;
  let alertIcon;
  let AlertTitle;
  switch (variant) {
    case "caution":
      bgColor = "bg-[var(--emt-background-surface-caution)]";
      alertIcon = <CautionAlertIcon />
      AlertTitle = "Caution"
      break;
    case "success":
      bgColor = "bg-[var(--emt-background-surface-success)]";
      alertIcon = <SuccessAlertIcon />
      AlertTitle = "Success"
      break;
    case "danger":
      bgColor = "bg-[var(--emt-background-surface-danger)]";
      alertIcon = <DangerAlertIcon />
      AlertTitle = "Danger"
      break;
    default:
      bgColor = "bg-[var(--emt-background-surface-informative)]";
      alertIcon = <InfoAlertIcon />
      AlertTitle = "Info"
  }

  return (
    <div className={`py-[var(--emt-space-400)] px-[var(--emt-space-300)] flex-auto flex items-start justify-between gap-[var(--emt-space-200)] rounded-md ${bgColor} w-full`}>
      <div className="flex items-start gap-[var(--emt-space-300)]">
        {
          prefix ? (
            prefix === true ? <span className="">
              {alertIcon}
            </span> : prefix
          ) : ""
        }
        <div className="flex flex-col gap-[var(--emt-space-100)]">
          <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">{
            title && (
              title === true ? AlertTitle : title
            )
          }</h5>
          <p className="text-[var(--emt-text-primary)] font-medium text-lg">{desc}</p>
        </div>
      </div>
      <div className="flex gap-[var(--emt-space-200)]">
        {
          action && <div className="flex items-center gap-[var(--emt-space-200)]">
            <ButtonElement size="sm" variant="white" />
            <ButtonElement size="sm" variant="outline" />
          </div>
        }
        {
          closable && <span className="cursor-pointer p-[var(--emt-space-50)] height-[var(--emt-space-600)] width-[var(--emt-space-600)]">
            <ModalCloseIcon height={8} width={8} />
          </span>
        }
      </div>
    </div>
  )
}
