import React from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import CurvedLineChartOptions from './CurvedLineChartOptions';
import './curved_line_tooltip.scss';
import HoverLine from './HoverLine';

Chart.register(CategoryScale);

const data = {
  labels: [
    ['01', 'February'],
    ['02', 'February'],
    ['03', 'February'],
    ['04', 'February'],
    ['05', 'February'],
    ['06', 'February'],
    ['07', 'February'],
    ['08', 'February'],
  ],
  datasets: [
    {
      label: 'Delivery error rate',
      fill: true,
      backgroundColor: (context) => {
        if (!context.chart.chartArea) {
          return;
        }
        const {
          ctx,
          chartArea: { top, bottom },
        } = context.chart;
        const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
        gradientBg.addColorStop(0, 'rgba(127, 92, 246, 0.25)');
        gradientBg.addColorStop(1, 'rgba(127, 92, 246, 0)');
        return gradientBg;
      },
      tension: 0.4,
      borderColor: '#7F5CF6',
      borderCapStyle: 'butt',
      borderJoinStyle: 'miter',
      pointBorderColor: '#7F5CF6',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#7F5CF6',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 8,
      data: [2.1, 2.4, 2.8, 3.0, 2.7, 2.1, 1.9, 2.1],
    },
  ],
};

const CurvedLineChart = () => {
  return (
    <>
      <Line
        data={data}
        options={CurvedLineChartOptions}
        plugins={[HoverLine]}
      />
    </>
  );
};

export default CurvedLineChart;
