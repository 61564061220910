export const DatePickerSnippet = {
  normal: {
    key: '1',
    code: `
import DatePickerElement from '@email-tracker';
import React, { useState } from 'react'

const Element = () => {

  const [value, onChange] = useState(new Date());

  return (
    <DatePickerElement value={value} onChange={onChange} />
  );
};
    
    export default Element;
`.trim()
  },
};
