import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout';
import ButtonElement from '../../theme/Components/Button/Button';
import { ButtonSnippet } from '../../theme/Components/Button/CodeSnippet';

function SecondaryButton() {
  const buttonSizes = [
    { size: 'lg', title: 'large', desc: "Large secondary buttons can be used when the call to action needs to appear prominently in the design" },
    { size: 'md', title: 'normal', desc: "The default button size for secondary buttons; it is used whenever an alternative call to action is required in the design" },
    { size: 'sm', title: 'small', desc: "Small secondary buttons can be used when the call to action does not have to appear prominently in the design" },
    { size: 'lg', title: 'fluid', desc: "Button that adapts to the size of the screen to make it consistent across devices", fluid: true },
    { size: 'lg', title: 'disabled', desc: "Indicates a CTA that is not yet active and clickable for the user", disabled: true },
  ];
  return (
    <div>
      <h3 className="section-title">Secondary</h3>
      <p className="comp-desc">
        Secondary buttons are less prominent calls to action, and are usually there to support the primary button. It gives the user an alternative option, and therefore ensures there is flexibility in the design.
      </p>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        {buttonSizes.map(({ size, title, desc, fluid, disabled }, i) => (
          <CompLayout
            key={title}
            component={<ButtonElement size={size} variant="secondary" fluid={fluid} disabled={disabled} />}
            title={title}
            desc={desc}
            code={ButtonSnippet.secondary[i].code}
          />
        ))}
      </div>
    </div>
  )
}

export default SecondaryButton