import React from 'react';
import CompLayout from '../theme/Layout/CompLayout';
import { StepsElement } from '../theme/Components/Steps/Steps';
import { StepsSnippet } from '../theme/Components/Steps/CodeSnippet';

export default function StepsComp() {

  return (
    <div className="flex flex-col gap-[52px]">
      <CompLayout
        component={<StepsElement />}
        title="Normal"
        desc="The default version of steps indicator used to indicate to the user which stage they are in using a horizontal bar composed of distinct circles and labels"
        code={StepsSnippet.normal.code}
      />
      <CompLayout
        component={<StepsElement type={'dense'} />}
        title="Dense"
        desc="A variant of the steps indicator where the elements are smaller and more closely arranged to save space"
        code={StepsSnippet.dense.code}
      />
    </div>
  );
}
