export const GraphCodeSnippet = {
  "Bar Chart": {
    key: '1',
    code: `
import BarChart from '@email-tracker';
    
const Element = () => {
    
  return (
    <BarChart inputTooltip />
  );
};
    
export default Element;
  `.trim()
  },
  "Stacked Bar Chart": {
    key: '2',
    code: `
import StackedBarChart from '@email-tracker';
    
const Element = () => {
    
  return (
    <StackedBarChart />
  );
};
    
export default Element;
  `.trim()
  },
  "Line Chart": {
    key: '3',
    code: `
import LineChart from '@email-tracker';
    
const Element = () => {
    
  return (
    <LineChart />
  );
};
    
export default Element;
  `.trim()
  },
  "Multi Bar Chart": {
    key: '4',
    code: `
import MultiBarChart from '@email-tracker';
    
const Element = () => {
    
  return (
    <MultiBarChart />
  );
};
    
export default Element;
  `.trim()
  },
  "Curved Line Chart": {
    key: '5',
    code: `
import CurvedLineChart from '@email-tracker';
    
const Element = () => {
    
  return (
    <CurvedLineChart />
  );
};
    
export default Element;
  `.trim()
  },
  "Straight Line Chart": {
    key: '6',
    code: `
import StraightLineChart from '@email-tracker';
    
const Element = () => {
    
  return (
    <StraightLineChart />
  );
};
    
export default Element;
  `.trim()
  },
  "Stacked Pie Chart": {
    key: '7',
    code: `
import StackedPieChart from '@email-tracker';
    
const Element = () => {
    
  return (
    <StackedPieChart />
  );
};
    
export default Element;
  `.trim()
  },
}