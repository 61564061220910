export const PaginationSnippet = {
  normal: {
    key: '1',
    code:
      `import PaginationElement from '@email-tracker'

const Element = () =>{
return(
  <PaginationElement />
)

export default Element`
  },
  noOfRows: {
    key: '2',
    code:
      `import PaginationElement from '@email-tracker'

const Element = () =>{
return(
  <TablePagination />
)

export default Element`
  },
}