import React from 'react'
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import './dropdown.scss'

function DropdownElement({ children, Items }) {
  return (
    <Dropdown
      classNames={{
        content: `min-w-[220px] shadow-[var(--emt-shadow-300)] p-[var(--emt-space-100)] rounded-md border border-[var(--emt-border-primary)]`,
      }}

    >
      <DropdownTrigger>
        {children}
      </DropdownTrigger>
      <DropdownMenu items={Items}
        classNames={{
          base: 'p-[var(--emt-space-0)]',
          list: 'gap-[var(--emt-space-0)] mail-dropdown-list'
        }}
      >
        {(item) => (
          <DropdownItem
            key={item.key}
          >
            {item.label}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropdownElement