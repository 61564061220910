import ExternalTooltipHandler from "./ExternalTooltipHandler";

const CurvedLineChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      beginAtZero: true,
      border: {
        dash: [5, 5],
        color: "transparent",
      },
      grid: {
        color: "#DCDFE4",
        tickColor: "transparent"
      },
      title: {
        display: true,
        text: "Volume or traffic Rejected or Temp-failed",
        font: {
          family: 'Manrope',
          weight: '500',
          size: 12,
          lineHeight: '14.4px',
        },
        color: "#0C0D10",
        padding: {
          bottom: 16
        }
      },
      min: 0,
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        },
        callback: function (value) {
          return value + "%";
        }
      }
    },
    x: {
      beginAtZero: true,
      border: {
        width: 1,
        color: "#DCDFE4"
      },
      grid: {
        offset: false,
        drawOnChartArea: false,
        color: "#DCDFE4",
        tickLength: 6,
        tickWidth: 1,
      },
      ticks: {
        align: 'center',
        padding: 8,
        callback: function (value) {
          return this.getLabelForValue(value).map((label, index) => index === 1 ? label.substr(0, 3) : label);
        },
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        }
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: ExternalTooltipHandler,
      callbacks: {
        title: (context) => {
          return context[0].label.replaceAll(',', ' ');
        }
      }
    },
    legend: {
      display: false
    }
  }
}

export default CurvedLineChartOptions