export const NavbarSnippet = {
  organization: {
    key: '1',
    code: `
import Navbar from '@email-tracker';

const Element = () => {

  return (
    <Navbar organization />
  );
};
    
    export default Element;
`.trim()
  },
  settings: {
    key: '1',
    code: `
import Navbar from '@email-tracker';

const Element = () => {

  return (
    <Navbar settings />
  );
};
    
    export default Element;
`.trim()
  },
};
