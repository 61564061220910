import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import ToasterElement from '../theme/Components/Toaster/Toaster'
import { ToasterSnippet } from '../theme/Components/Toaster/CodeSnippet'
function ToasterComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <ToasterElement />
        }
        title="Normal"
        desc="A type of pop up that is designed to be simple and take up very little space. It usually disappear in seconds without any action from the user"
        code={ToasterSnippet.normal.code}
      />
      <CompLayout
        component={
          <ToasterElement prefix />
        }
        title="with prefix"
        desc="Variant of the toaster pop up with a icon present at the start for easy identification"
        code={ToasterSnippet.prefix.code}
      />
      <CompLayout
        component={
          <ToasterElement closable />
        }
        title="with close button"
        desc="Variant of the toaster pop up with a button that allows the user to manually close it"
        code={ToasterSnippet.closable.code}
      />
      <CompLayout
        component={
          <ToasterElement prefix closable />
        }
        title="with prefix and close button"
        desc="Variant of the toaster pop up with an icon in the front for easy identification and a button to manually close it"
        code={ToasterSnippet.prefixAndClosable.code}
      />
    </div>
  )
}

export default ToasterComp