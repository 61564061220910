export const CopyIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_3891_850)">
        <path
          d="M2.5 15.4199V13.7533H4.16667V15.4199H2.5ZM2.5 12.5033V10.8366H4.16667V12.5033H2.5ZM2.5 9.58659V7.91992H4.16667V9.58659H2.5ZM5.41667 18.3366V16.6699H7.08333V18.3366H5.41667ZM7.5 15.0033C7.04167 15.0033 6.64931 14.8401 6.32292 14.5137C5.99653 14.1873 5.83333 13.7949 5.83333 13.3366V3.33659C5.83333 2.87826 5.99653 2.48589 6.32292 2.15951C6.64931 1.83312 7.04167 1.66992 7.5 1.66992H15C15.4583 1.66992 15.8507 1.83312 16.1771 2.15951C16.5035 2.48589 16.6667 2.87826 16.6667 3.33659V13.3366C16.6667 13.7949 16.5035 14.1873 16.1771 14.5137C15.8507 14.8401 15.4583 15.0033 15 15.0033H7.5ZM7.5 13.3366H15V3.33659H7.5V13.3366ZM8.33333 18.3366V16.6699H10V18.3366H8.33333ZM4.16667 18.3366C3.70833 18.3366 3.31597 18.1734 2.98958 17.847C2.66319 17.5206 2.5 17.1283 2.5 16.6699H4.16667V18.3366ZM11.25 18.3366V16.6699H12.9167C12.9167 17.1283 12.7535 17.5206 12.4271 17.847C12.1007 18.1734 11.7083 18.3366 11.25 18.3366ZM2.5 6.66992C2.5 6.21159 2.66319 5.81923 2.98958 5.49284C3.31597 5.16645 3.70833 5.00326 4.16667 5.00326V6.66992H2.5Z"
          fill="#151819"
        />
      </g>
    </svg>
  );
};

export const CustomIcon = ({ stroke = 'white', size = '24' }, props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="4.75"
        y="4.75"
        width="14.3581"
        height="14.3581"
        stroke={stroke}
        stroke-width="1.5"
      />
      <path d="M5.5 5.5L18.3615 18.3615" stroke={stroke} stroke-width="1.5" />
      <path d="M18.3563 5.5L5.5 18.3563" stroke={stroke} stroke-width="1.5" />
    </svg>
  );
};
export const CloseIcon = (props) => {
  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.66483 1.83667L1.33854 7.1612"
        stroke="#363A44"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6.66667 7.16659L1.33333 1.83325"
        stroke="#363A44"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
export const ModalCloseIcon = (props) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6643 1.00684L1.01172 11.6559"
        stroke="#0C0D10"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11.6667 11.6667L1 1"
        stroke="#0C0D10"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.84394"
        cy="7.84394"
        r="5.99237"
        stroke="#1F2229"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0117 12.3242L14.3611 14.6675"
        stroke="#1F2229"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ToasterCloseIcon = ({ stroke = 'white' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill={stroke} fill-opacity="0.15" />
      <path
        d="M21.3298 10.6729L10.6772 21.3219"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M21.3332 21.3337L10.6665 10.667"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
export const ToasterPrefix = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3335 10.0833L8.05705 14.6667L17.5002 5.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const ErrorMessageIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.6665 7.99935C2.6665 5.05588 5.05584 2.66602 7.99984 2.66602C10.9492 2.66602 13.3332 5.05588 13.3332 7.99935C13.3332 10.9439 10.9492 13.3327 7.99984 13.3327C5.05584 13.3327 2.6665 10.9439 2.6665 7.99935ZM9.96681 9.96256C10.1886 9.74159 10.1886 9.38309 9.96681 9.16147L8.80555 8.00122L9.96681 6.84033C10.1886 6.61936 10.1886 6.25434 9.96681 6.03272C9.745 5.81045 9.38618 5.81045 9.15784 6.03272L8.0031 7.19231L6.84184 6.03272C6.6135 5.81045 6.25468 5.81045 6.03286 6.03272C5.81105 6.25434 5.81105 6.61936 6.03286 6.84033L7.19413 8.00122L6.03286 9.15495C5.81105 9.38309 5.81105 9.74159 6.03286 9.96256C6.14377 10.0734 6.29382 10.1327 6.43735 10.1327C6.5874 10.1327 6.73093 10.0734 6.84184 9.96256L8.0031 8.80948L9.16436 9.96256C9.27527 10.0805 9.4188 10.1327 9.56232 10.1327C9.71237 10.1327 9.8559 10.0734 9.96681 9.96256Z"
        fill="#D31510"
      />
    </svg>
  );
};
export const DropdownArrow = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6673 6.16699L8.00065 10.8337L3.33398 6.16699"
        stroke="#0C0D10"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const InfoAlertIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 10C17.5 14.1393 14.14 17.5 10 17.5C5.8525 17.5 2.5 14.1392 2.5 10C2.5 5.85925 5.8525 2.5 10 2.5C14.14 2.5 17.5 5.85925 17.5 10ZM10.6592 12.841C10.6592 13.2002 10.3592 13.501 9.99917 13.501C9.63917 13.501 9.34668 13.2002 9.34668 12.841L9.34668 9.52598C9.34668 9.16523 9.63918 8.87348 9.99917 8.87348C10.3592 8.87348 10.6592 9.16523 10.6592 9.52598L10.6592 12.841ZM9.99186 6.48779C10.3594 6.48779 10.6519 6.78779 10.6519 7.14779C10.6519 7.50779 10.3594 7.80029 9.99936 7.80029C9.63186 7.80029 9.33936 7.50779 9.33936 7.14779C9.33936 6.78779 9.63186 6.48779 9.99186 6.48779Z"
        fill="#0E56BA"
      />
    </svg>
  );
};
export const CautionAlertIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8579 4.20657L17.3095 13.6679C17.4295 13.9503 17.482 14.18 17.497 14.4185C17.527 14.9759 17.332 15.5177 16.9496 15.9346C16.5671 16.35 16.0497 16.5953 15.4873 16.625H4.50918C4.27672 16.6109 4.04426 16.5581 3.82679 16.4764C2.73948 16.0379 2.21456 14.8042 2.65699 13.7348L8.14606 4.19988C8.33353 3.86471 8.61848 3.57561 8.97093 3.38982C9.99075 2.82427 11.288 3.19585 11.8579 4.20657ZM10.6531 10.4443C10.6531 10.801 10.3606 11.099 10.0007 11.099C9.64077 11.099 9.34082 10.801 9.34082 10.4443V8.34187C9.34082 7.98441 9.64077 7.69531 10.0007 7.69531C10.3606 7.69531 10.6531 7.98441 10.6531 8.34187V10.4443ZM10.0007 13.6378C9.64077 13.6378 9.34082 13.3398 9.34082 12.9838C9.34082 12.6264 9.64077 12.3291 10.0007 12.3291C10.3606 12.3291 10.6531 12.6197 10.6531 12.9757C10.6531 13.3398 10.3606 13.6378 10.0007 13.6378Z"
        fill="#2B2E37"
      />
    </svg>
  );
};
export const SuccessAlertIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0ZM12.0547 6.30469L7.72131 10.6379C7.59131 10.7679 7.42065 10.8334 7.25 10.8334C7.07935 10.8334 6.90869 10.7679 6.77869 10.6379L4.61206 8.47131C4.35132 8.21069 4.35132 7.78931 4.61206 7.52869C4.87268 7.26794 5.29395 7.26794 5.55469 7.52869L7.25 9.224L11.1121 5.36206C11.3727 5.10132 11.7939 5.10132 12.0547 5.36206C12.3153 5.62268 12.3153 6.04395 12.0547 6.30469Z"
        fill="#48AB81"
      />
    </svg>
  );
};
export const DangerAlertIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#C62127" />
      <path
        d="M10.7977 5.2041L5.20508 10.7949"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M10.7992 10.8002L5.19922 5.2002"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const HamburgerIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 10H17.5" stroke="#0C0D10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.5 5H17.5" stroke="#0C0D10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.5 15H17.5" stroke="#0C0D10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const QuestionIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.11171 9.2044V8.89039C8.11171 7.8642 8.74601 7.30777 9.38157 6.88071C10.0021 6.46245 10.6238 5.91733 10.6238 4.91249C10.6238 3.52455 9.49964 2.40039 8.11171 2.40039C6.72377 2.40039 5.59961 3.52455 5.59961 4.91249" stroke="#2B2E37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.11115 12.6578C7.93781 12.6578 7.79714 12.7984 7.79839 12.9718C7.79839 13.1451 7.93907 13.2858 8.1124 13.2858C8.28574 13.2858 8.42642 13.1451 8.42642 12.9718C8.42642 12.7984 8.28574 12.6578 8.11115 12.6578" stroke="#2B2E37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const NotificationIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7877 6.23832C11.7877 6.94755 11.9752 7.36558 12.3877 7.84731C12.7003 8.20221 12.8002 8.65778 12.8002 9.15203C12.8002 9.6457 12.638 10.1144 12.313 10.4948C11.8876 10.951 11.2877 11.2422 10.6753 11.2928C9.78802 11.3684 8.90014 11.4321 8.00048 11.4321C7.10025 11.4321 6.21293 11.394 5.32561 11.2928C4.71274 11.2422 4.11277 10.951 3.68791 10.4948C3.36295 10.1144 3.2002 9.6457 3.2002 9.15203C3.2002 8.65778 3.30066 8.20221 3.61271 7.84731C4.03813 7.36558 4.21323 6.94755 4.21323 6.23832V5.99774C4.21323 5.04792 4.45008 4.42685 4.93779 3.81885C5.66291 2.93217 6.82524 2.40039 7.97522 2.40039H8.02573C9.20041 2.40039 10.4003 2.95777 11.1131 3.88255C11.5756 4.47804 11.7877 5.07295 11.7877 5.99774V6.23832ZM6.34707 12.5991C6.34707 12.3148 6.60805 12.1845 6.84938 12.1288C7.13168 12.0691 8.85188 12.0691 9.13418 12.1288C9.37552 12.1845 9.63649 12.3148 9.63649 12.5991C9.62246 12.8699 9.46363 13.1099 9.24419 13.2623C8.95964 13.4841 8.6257 13.6246 8.27661 13.6752C8.08354 13.7002 7.89384 13.7008 7.70751 13.6752C7.35786 13.6246 7.02392 13.4841 6.73994 13.2617C6.51993 13.1099 6.3611 12.8699 6.34707 12.5991Z" fill="#363A44" />
    </svg>
  )
}
export const PaginationPrevious = ({ stroke = "#0C0D10" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3333 12.6667L5.66665 8.00004L10.3333 3.33337"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const PaginationNext = ({ stroke = '#0C0D10' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66663 3.33329L10.3333 7.99996L5.66663 12.6666"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const DangerousInfoIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.66675 8.00002C2.66675 5.05655 5.05608 2.66669 8.00008 2.66669C10.9494 2.66669 13.3334 5.05655 13.3334 8.00002C13.3334 10.9446 10.9494 13.3334 8.00008 13.3334C5.05608 13.3334 2.66675 10.9446 2.66675 8.00002ZM9.96705 9.96323C10.1889 9.74226 10.1889 9.38376 9.96705 9.16214L8.80579 8.00189L9.96705 6.841C10.1889 6.62003 10.1889 6.25501 9.96705 6.03339C9.74524 5.81112 9.38642 5.81112 9.15808 6.03339L8.00334 7.19298L6.84208 6.03339C6.61374 5.81112 6.25492 5.81112 6.03311 6.03339C5.81129 6.25501 5.81129 6.62003 6.03311 6.841L7.19437 8.00189L6.03311 9.15562C5.81129 9.38376 5.81129 9.74226 6.03311 9.96323C6.14402 10.074 6.29407 10.1334 6.43759 10.1334C6.58765 10.1334 6.73117 10.074 6.84208 9.96323L8.00334 8.81015L9.16461 9.96323C9.27551 10.0812 9.41904 10.1334 9.56257 10.1334C9.71262 10.1334 9.85615 10.074 9.96705 9.96323Z"
        fill="#DC2018"
      />
    </svg>
  );
};

export const AddIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 8.5H3" stroke="#1F2229" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 13.5V3.5" stroke="#1F2229" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const AccordionIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0625 11.3994L8 14.4619L4.9375 11.3994" stroke="#7F8592" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.9375 4.46191L8 1.39941L11.0625 4.46191" stroke="#7F8592" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
export const DatePickerIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.99609" y="2.99658" width="18.0075" height="18.0075" rx="3" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.0036 7.99854H2.99609" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.3022 11.4997C17.3022 11.5273 17.2798 11.5497 17.2522 11.5497C17.2245 11.5497 17.2021 11.5273 17.2021 11.4997C17.2021 11.4721 17.2245 11.4497 17.2522 11.4497" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.2506 11.4498C17.2782 11.4498 17.3006 11.4722 17.3006 11.4998" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.7983 11.4997C13.7983 11.5273 13.7759 11.5497 13.7483 11.5497C13.7206 11.5497 13.6982 11.5273 13.6982 11.4997C13.6982 11.4721 13.7206 11.4497 13.7483 11.4497" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.7486 11.4498C13.7763 11.4498 13.7987 11.4722 13.7987 11.4998" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.2983 11.4997C10.2983 11.5273 10.2759 11.5497 10.2483 11.5497C10.2206 11.5497 10.1982 11.5273 10.1982 11.4997C10.1982 11.4721 10.2206 11.4497 10.2483 11.4497" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.2467 11.4498C10.2743 11.4498 10.2967 11.4722 10.2967 11.4998" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.79632 14.5012C6.79632 14.5288 6.77393 14.5512 6.7463 14.5512C6.71868 14.5512 6.69628 14.5288 6.69628 14.5012C6.69628 14.4736 6.71868 14.4512 6.7463 14.4512" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.74668 14.4513C6.77431 14.4513 6.7967 14.4737 6.7967 14.5013" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.2983 14.5012C10.2983 14.5288 10.2759 14.5512 10.2483 14.5512C10.2206 14.5512 10.1982 14.5288 10.1982 14.5012C10.1982 14.4736 10.2206 14.4512 10.2483 14.4512" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.2467 14.4513C10.2743 14.4513 10.2967 14.4737 10.2967 14.5013" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.7983 14.5012C13.7983 14.5288 13.7759 14.5512 13.7483 14.5512C13.7206 14.5512 13.6982 14.5288 13.6982 14.5012C13.6982 14.4736 13.7206 14.4512 13.7483 14.4512" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.7486 14.4513C13.7763 14.4513 13.7987 14.4737 13.7987 14.5013" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.3022 14.5012C17.3022 14.5288 17.2798 14.5512 17.2522 14.5512C17.2245 14.5512 17.2021 14.5288 17.2021 14.5012C17.2021 14.4736 17.2245 14.4512 17.2522 14.4512" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.2506 14.4513C17.2782 14.4513 17.3006 14.4737 17.3006 14.5013" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.3022 17.5026C17.3022 17.5303 17.2798 17.5527 17.2522 17.5527C17.2245 17.5527 17.2021 17.5303 17.2021 17.5026C17.2021 17.475 17.2245 17.4526 17.2522 17.4526" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.2506 17.4522C17.2782 17.4522 17.3006 17.4746 17.3006 17.5023" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.7983 17.5026C13.7983 17.5303 13.7759 17.5527 13.7483 17.5527C13.7206 17.5527 13.6982 17.5303 13.6982 17.5026C13.6982 17.475 13.7206 17.4526 13.7483 17.4526" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.7486 17.4522C13.7763 17.4522 13.7987 17.4746 13.7987 17.5023" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.2983 17.5026C10.2983 17.5303 10.2759 17.5527 10.2483 17.5527C10.2206 17.5527 10.1982 17.5303 10.1982 17.5026C10.1982 17.475 10.2206 17.4526 10.2483 17.4526" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.2467 17.4522C10.2743 17.4522 10.2967 17.4746 10.2967 17.5023" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.79632 17.5026C6.79632 17.5303 6.77393 17.5527 6.7463 17.5527C6.71868 17.5527 6.69628 17.5303 6.69628 17.5026C6.69628 17.475 6.71868 17.4526 6.7463 17.4526" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.74668 17.4522C6.77431 17.4522 6.7967 17.4746 6.7967 17.5023" stroke="#2B2E37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export const TreeClose = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" fill="#F1F4F8" stroke="#D0D5E0" />
      <path d="M10.0002 5.00391C10.1101 5.00391 10.22 5.04588 10.3038 5.12964L12.941 7.7668C13.1087 7.93456 13.1087 8.20655 12.941 8.37423C12.7733 8.54192 12.5013 8.54192 12.3336 8.37423L10.0002 6.04073L7.66681 8.37415C7.49905 8.54184 7.22714 8.54184 7.05947 8.37415C6.89163 8.20646 6.89163 7.93448 7.05947 7.76672L9.69656 5.12956C9.78041 5.04578 9.89031 5.00391 10.0002 5.00391Z" fill="#363A44" />
      <path d="M9.99969 14.9961C9.88977 14.9961 9.77986 14.9541 9.69606 14.8704L7.05892 12.2332C6.89117 12.0654 6.89117 11.7935 7.05892 11.6258C7.22661 11.4581 7.49854 11.4581 7.66631 11.6258L9.99969 13.9593L12.3331 11.6258C12.5008 11.4582 12.7727 11.4582 12.9404 11.6258C13.1082 11.7935 13.1082 12.0655 12.9404 12.2333L10.3033 14.8704C10.2195 14.9542 10.1096 14.9961 9.99969 14.9961Z" fill="#363A44" />
    </svg>

  )
}
export const TreeOpen = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.1055" r="9.5" fill="#F1F4F8" stroke="#D0D5E0" />
      <path d="M10.0002 9.10547C9.89026 9.10547 9.78035 9.0635 9.69655 8.97974L7.05941 6.34257C6.89165 6.17482 6.89165 5.90283 7.05941 5.73514C7.2271 5.56745 7.49903 5.56745 7.6668 5.73514L10.0002 8.06865L12.3336 5.73522C12.5013 5.56753 12.7732 5.56753 12.9409 5.73522C13.1087 5.90291 13.1087 6.1749 12.9409 6.34266L10.3038 8.97982C10.22 9.06359 10.1101 9.10547 10.0002 9.10547Z" fill="#363A44" />
      <path d="M9.99983 11.1055C10.1097 11.1055 10.2197 11.1474 10.3035 11.2312L12.9406 13.8684C13.1083 14.0361 13.1083 14.3081 12.9406 14.4758C12.7729 14.6435 12.501 14.6435 12.3332 14.4758L9.99983 12.1423L7.66644 14.4757C7.49868 14.6434 7.22678 14.6434 7.0591 14.4757C6.89126 14.308 6.89126 14.036 7.0591 13.8683L9.6962 11.2311C9.78004 11.1473 9.88995 11.1055 9.99983 11.1055Z" fill="#363A44" />
    </svg>
  )
}
export const TickIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66602 7.66667L6.44485 11.3333L13.9993 4" stroke="#4E46B4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
