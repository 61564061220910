import React from 'react'
import PrimaryButton from '../components/Button/PrimaryButton'
import SecondaryButton from '../components/Button/SecondaryButton'
import OutlineButton from '../components/Button/OutlineButton'
import GhostButton from '../components/Button/GhostButton'
import DangerButton from '../components/Button/DangerButton'
import LinkButton from '../components/Button/LinkButton'
import WhiteButton from '../components/Button/WhiteButton'

function ButtonComp() {
    return (
        <div className='flex flex-col gap-[52px]'>
            <PrimaryButton />
            <SecondaryButton />
            <WhiteButton />
            <OutlineButton />
            <GhostButton />
            <DangerButton />
            <LinkButton />
        </div>
    )
}

export default ButtonComp