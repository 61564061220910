const DrawOrderPlugin = {
  id: 'custom',
  beforeDraw: (chart) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
  },
  afterDraw: (chart) => {
    chart.ctx.restore();
  }
}

export default DrawOrderPlugin;