export const FormSnippet = {
  normal: {
    key: '1',
    code: `
import React from 'react'
import { InputElement, CheckboxElement, ButtonElement } from '@email-tracker';
import { useForm, Controller } from "react-hook-form";
    
    
function FormValidationComp() {
    
   const {
    handleSubmit,
     control,
    formState: { errors },
   } = useForm();
    
   const onSubmit = (data) => {
    console.log(data);
  };
    
  return (
     <form onSubmit={handleSubmit(onSubmit)} className='flex gap-4 flex-col w-[400px]'>

       <Controller
        name="firstName"
        control={control}
         rules={{ required: "First name is required" }}
         render={({ field, fieldState: { error } }) => (
           <InputElement
            {...field}
            label="First Name"
             isInvalid={!!error}
          />
        )}
      />
    
       <Controller
        name="lastName"
        control={control}
         rules={{ required: "Last name is required" }}
        render={({ field, fieldState: { error } }) => (
          <InputElement
            {...field}
             label="Last Name"
             isInvalid={!!error}
           />
         )}
      />
    
       <Controller
         name="updates"
        control={control}
        rules={{
          required: "You must allow updates through WhatsApp to proceed.",
        }}
        render={({ field, fieldState: { error } }) => (
          <CheckboxElement
            {...field}
            validation={error}
             checked={field.value}
            children="Allow updates through WhatsApp"
          />
         )}
       />

       <ButtonElement type="submit" text="Submit" fluid className="mt-6" />
     </form>
   )
 }
    
export default FormValidationComp
`.trim()
  },
}