import React from 'react'
import CardElement from '../../theme/Components/Card/Card';
import CompLayout from '../../theme/Layout/CompLayout';
import { CardSnippet } from '../../theme/Components/Card/CodeSnippet';

export const LargeCard = () => {
  const renderCard = (bg) => (
    <CardElement size='lg' bg={bg}>
      <div className="flex flex-col gap-2">
        <h5 className="font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  );

  const cards = [
    { title: "Normal", bg: undefined, desc: "The default version of the large card, which contains a large set of information" },
    { title: "With default background", bg: "white", desc: "Variant of a large card with a backgroun that matches the rest of the system and is differentiated using a border" },
    { title: "With dark background", bg: "dark", desc: "Variant of a large card with a dark blue background" },
    { title: "With light background", bg: "light", desc: "Variant of a large card with a light blue background" },
    { title: "Stroke", bg: "stroke", desc: "A large card is a container used to present a large set of information in a concise manner" }
  ];

  return (
    <div>
      <h3 className="section-title">Large</h3>
      <p className="comp-desc">
        A large card is used to group information together to quickly view
      </p>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        {cards.map((card, index) => (
          <CompLayout
            key={index}
            component={renderCard(card.bg)}
            title={card.title}
            desc={card.desc}
            code={CardSnippet.large[index].code}
          />
        ))}
      </div>
    </div>
  )
}
