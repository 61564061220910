import React from 'react'
import { LargeCard } from '../components/Card/LargeCard'
import { NormalCard } from './../components/Card/NormalCard';
import { SmallCard } from './../components/Card/SmallCard';
function CardComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <LargeCard />
      <NormalCard />
      <SmallCard />
    </div>
  )
}

export default CardComp