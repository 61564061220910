import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './layout.module.scss';
function Layout({ children, title }) {
  const location = useLocation();
  const Links = [
    {
      key: '1',
      link: '/alert',
      title: 'alert'
    },
    {
      key: '2',
      link: '/avatar',
      title: 'avatar'
    },
    {
      key: '3',
      link: '/',
      title: 'button'
    },
    {
      key: '4',
      link: '/card',
      title: 'card'
    },
    {
      key: '5',
      link: '/checkbox',
      title: 'checkbox'
    },
    {
      key: '6',
      link: '/chips',
      title: 'Chips'
    },
    {
      key: '7',
      link: '/datepicker',
      title: 'datepicker'
    },
    {
      key: '8',
      link: '/drawer',
      title: 'drawer'
    },
    {
      key: '9',
      link: '/dropdown',
      title: 'dropdown'
    },
    {
      key: '10',
      link: '/empty',
      title: 'empty'
    },
    {
      key: '11',
      link: '/form',
      title: 'form'
    },
    {
      key: '12',
      link: '/graph',
      title: 'Graph'
    },
    {
      key: '13',
      link: '/input',
      title: 'input'
    },
    {
      key: '14',
      link: '/modal',
      title: 'modal'
    },
    {
      key: '15',
      link: '/navbar',
      title: 'navbar'
    },
    {
      key: '16',
      link: '/pagination',
      title: 'pagination'
    },
    {
      key: '17',
      link: '/radio',
      title: 'radio'
    },
    {
      key: '18',
      link: '/search',
      title: 'search'
    },
    {
      key: '19',
      link: '/select',
      title: 'select'
    },
    {
      key: '20',
      link: '/steps',
      title: 'steps'
    },
    {
      key: '21',
      link: '/table',
      title: 'table'
    },
    {
      key: '22',
      link: '/tab',
      title: 'tab'
    },
    {
      key: '23',
      link: '/tag',
      title: 'tag'
    },
    {
      key: '24',
      link: '/toaster',
      title: 'toaster'
    },
    {
      key: '25',
      link: '/toggle',
      title: 'toggle'
    },
    {
      key: '26',
      link: '/tooltip',
      title: 'tooltip'
    },
    {
      key: '27',
      link: '/tree',
      title: 'tree'
    }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])
  return (
    <div className={style['main-container']}>
      <nav className={style['navbar']}>
        <Link to={'/'} className={style['nav-logo']}>
          <img src={`/images/svg/logo-light.svg`} alt="" />
        </Link>
        <h6 className={style['component-text']}>Components</h6>
        <div className={style['link-wrapper']}>
          {Links.map((link) => {
            return (
              <Link
                key={link.key}
                to={link.link}
                className={`${style['nav-link']} ${location.pathname === link.link && style['active']
                  }`}
              >
                {link.title}
              </Link>
            );
          })}
        </div>
      </nav>
      <main className={style['main-content']}>
        <header className={style['header']}>
          <h4 className={style['component-title']}>{title}</h4>
        </header>
        <section className={style['content-section']}>{children}</section>
      </main>
    </div>
  );
}

export default Layout;
