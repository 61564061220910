import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout';
import { Alert } from '../../theme/Components/Alert/Alert';
import { AlertSnippet } from '../../theme/Components/Alert/CodeSnippet';

export const Informative = () => {
  const alertConfigurations = [
    { title: "Normal", component: <Alert />, desc: "The default version of the informative alert, which helps deliver messages or details to the user" },
    { title: "with prefix", component: <Alert prefix />, desc: "Variant of an informative alert, it has an icon or label at the start for easy identification" },
    { title: "with Action Buttons", component: <Alert action />, desc: "Variant of an informative alert, it has buttons for the user to take relevant actions based on the information" },
    { title: "without close button", component: <Alert closable={false} />, desc: "Variant of an informative alert which cannot be closed manually by the user and goes away after a delay" },
    { title: "with prefix and action", component: <Alert prefix action />, desc: "Variant of an informative alert which has an icon or label in the front for easy identification and buttons to help users take actions" }
  ];

  return (
    <div>
      <h3 className="section-title">Informative</h3>
      <p className="comp-desc">
        Type of alert which gives the user relevant messages or details about the sytem and has a blue background.
      </p>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        {alertConfigurations.map(({ title, component, desc }, i) => (
          <CompLayout
            key={i}
            component={component}
            title={title}
            desc={desc}
            code={AlertSnippet.information[i].code}
          />
        ))}
      </div>
    </div>
  );
};

