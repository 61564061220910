import React from 'react';
import { Pagination } from '@nextui-org/react';
import './pagination.scss';
import { PaginationNext, PaginationPrevious } from '../../icons';
import SelectElement from '../Select/Select';

function PaginationElement({
  page: currentPage,
  setPage = () => { },
  initialPage = 1,
  total = 10,
  siblings = 0,
  selectRowsPerPage = false,
  defaultSelectValue = '5',
  handleRowsPerPage = () => { },
  handlePageChange = () => { },
  selectData = [
    { key: '5', label: '5' },
    { key: '10', label: '10' },
    { key: '15', label: '15' },
    { key: '20', label: '20' },
  ],
}) {
  return (
    <div
      className={`flex w-full items-center justify-between bg-[var(--emt-background-secondary)] py-[var(--emt-space-300)] pr-[var(--emt-space-600)] pl-[var(--emt-space-600)] ${selectRowsPerPage ? 'justify-between' : '!justify-end'
        }`}
    >
      {selectRowsPerPage ? (
        <div className="flex items-center gap-[var(--emt-space-300)]">
          <h6 className="mail-pagination-info font-semibold">
            Items per page:
          </h6>
          <SelectElement
            className="pagination-select w-[var(--emt-space-2000)]"
            placeholder=""
            label=""
            defaultSelectedKeys={[defaultSelectValue]}
            data={selectData}
            onChange={handleRowsPerPage}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="flex gap-[var(--emt-space-150)]">
        <span
          className={`rounded-full cursor-pointer flex justify-center items-center border border-[var(--emt-border-secondary)] min-w-2 w-[var(--emt-space-800)] h-[var(--emt-space-800)] text-[var(--emt-text-primary)] font-semibold text-[13px] transition-background hover:bg-[var(--emt-background-surface-hover)] ${currentPage === 1 &&
            '!border-[var(--emt-border-disabled)] hover:!bg-transparent !cursor-no-drop'
            }`}
          onClick={() =>
            setPage((prev) => {
              if (prev > 1) {
                return prev - 1;
              }
              return prev;
            })
          }
        >
          <PaginationPrevious
            stroke={currentPage === 1 ? '#0C0D1014' : '#0C0D10'}
          />
        </span>
        <Pagination
          classNames={{
            wrapper: 'gap-[var(--emt-space-150)] mail-pagination',
            cursor:
              'bg-[var(--emt-background-fill-selected)] rounded-full min-w-2 !outline-none w-[var(--emt-space-800)] h-[var(--emt-space-800)] font-semibold text-sm text-[var(--emt-text-on-bg-fill)]',
            item: 'rounded-full !outline-none border bg-transparent border-[var(--emt-border-secondary)] min-w-2 w-[var(--emt-space-800)] h-[var(--emt-space-800)] text-[var(--emt-text-primary)] font-semibold text-[13px]',
          }}
          page={currentPage}
          siblings={siblings}
          total={total}
          initialPage={initialPage}
          onChange={(page) => handlePageChange(page)}
        />
        <span
          className={`rounded-full cursor-pointer flex justify-center items-center border border-[var(--emt-border-secondary)] min-w-2 w-[var(--emt-space-800)] h-[var(--emt-space-800)] text-[var(--emt-text-primary)] font-semibold text-[13px] transition-background hover:bg-[var(--emt-background-surface-hover)] ${currentPage === total &&
            '!border-[var(--emt-border-disabled)] hover:!bg-transparent !cursor-no-drop'
            }`}
          onClick={() =>
            setPage((prev) => {
              if (prev < total) {
                return prev + 1;
              }
              return prev;
            })
          }
        >
          <PaginationNext
            stroke={currentPage === total ? '#0C0D1014' : '#0C0D10'}
          />
        </span>
      </div>
    </div>
  );
}

export default PaginationElement;
