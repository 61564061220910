import ExternalTooltipHandler from "./ExternalTooltipHandler";

const barOptions = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      beginAtZero: false,
      border: {
        dash: [5, 5],
        color: "transparent",
      },
      grid: {
        color: "#D0D5E0",
        tickColor: "transparent",
      },
      title: {
        display: true,
        font: {
          family: 'Manrope',
          weight: '500',
          size: 12,
          lineHeight: '14.4px',
        },
        color: '#0C0D10',
        padding: {
          bottom: 16
        },
        text: 'Volume of user reported spam'
      },
      ticks: {
        stepSize: 1,
        beginAtZero: true,
        callback: function (value) {
          return value + '%';
        },
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        },
      },
    },
    x: {
      border: {
        color: "#D0D5E0",
      },
      grid: {
        offset: true,
        drawOnChartArea: false,
        drawBorder: false,
        tickColor: "#D0D5E0",
        tickLength: 6,
        tickWidth: 1,

      },
      ticks: {
        align: 'center',
        // labelOffset: -65,
        padding: 8,
        callback: function (value) {
          if (typeof this.getLabelForValue(value) === 'string') return this.getLabelForValue(value).substring(0, 6);
          return this.getLabelForValue(value).map((label, index) => index === 1 ? label.substring(0, 3) : label);
        },
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        },
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: ExternalTooltipHandler,
      callbacks: {
        title: (context) => {
          return context[0].label.replaceAll(',', ' ');
        }
      }
    },
    legend: {
      display: false,
    }
  }
};

export default barOptions;