import { Checkbox } from '@nextui-org/react'
import React from 'react'
import './checkbox.scss'
import { ErrorMessageIcon } from '../../icons'
function CheckboxElement({ checked, errorMessage = "Helpful validation message", children, onValueChange, validation, on, indeterminate, ...rest }) {
  return (
    <div>
      <Checkbox
        isIndeterminate={indeterminate}
        isSelected={checked}
        onChange={onValueChange}
        {...rest}
        classNames={{
          base: `flex gap-[var(--emt-space-200)] mail-checkbox ${validation && "mail-checkbox-error"}`,
          wrapper: `mail-checkbox-wrapper before:border-2 before:border-[var(--emt-background-tertiary)] w-[var(--emt-space-500)] h-[var(--emt-space-500)] before:rounded-sm rounded-sm mr-0 hover:border-[var(--emt-border-selected)] after:rounded-sm ${validation && "before:!border-[var(--emt-border-negative)] before:border-1"}`,
          label: "text-[var(--emt-text-primary)] font-medium text-lg"
        }}
      >{children}</Checkbox>
      {validation && (
        <div className='flex items-center mt-[var(--emt-space-200)] gap-[var(--emt-space-100)]'>
          <span className=""><ErrorMessageIcon /></span>
          <p className="text-[var(--emt-text-negative)] text-base leading-md font-medium">{errorMessage}</p>
        </div>
      )}
    </div>
  )
}

export default CheckboxElement