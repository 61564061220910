export const DropDownSnippet = {
  normal: {
    key: '1',
    code: `
import DropdownElement from '@email-tracker';
import ButtonElement from '@email-tracker/Button';
    
const Element = () => {
    
  const DropData = [
    { key: "new", label: "New file", },
    { key: "copy", label: "Copy link", },
    { key: "edit", label: "Edit file", },
    { key: "delete", label: "Delete file", }
  ];
    
  return (
    <DropdownElement Items={DropData}>
      <ButtonElement size='sm' text='Open Menu' />
    </DropdownElement>
  );
};
    
export default Element;
  `.trim()
  }

}