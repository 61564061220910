import React from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import StackedBarOptions from './StackedBarOptions';
import './stacked_bar_tooltip.scss';

Chart.register(CategoryScale);

const data = {
  labels: [
    ['01', 'February'],
    ['02', 'February'],
    ['03', 'February'],
    ['04', 'February'],
    ['05', 'February'],
    ['06', 'February'],
    ['07', 'February'],
    ['08', 'February'],
  ],
  datasets: [
    {
      label: 'Bad',
      afterTitle: 'IP Reputation',
      data: [0, 0, 0, 25, 100, 0, 75, 0],
      backgroundColor: '#C62127',
      barThickness: 16,
      barPercentage: 1,
      categoryPercentage: 0.5,
      borderRadius: {
        topRight: 4,
        topLeft: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      borderSkipped: false,
      borderWidth: {
        top: 1,
      },
      borderColor: 'transparent',
    },
    {
      label: 'Low',
      afterTitle: 'IP Reputation',
      data: [0, 0, 50, 25, 0, 100, 25, 0],
      backgroundColor: '#FF5A60',
      barThickness: 16,
      barPercentage: 1,
      categoryPercentage: 0.5,
      borderRadius: {
        topRight: 4,
        topLeft: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      borderSkipped: false,
      borderWidth: {
        top: 1,
      },
      borderColor: 'transparent',
    },
    {
      label: 'Medium',
      afterTitle: 'IP Reputation',
      data: [0, 100, 0, 25, 0, 0, 0, 100],
      backgroundColor: '#EDA210',
      barThickness: 16,
      barPercentage: 1,
      categoryPercentage: 0.5,
      borderRadius: {
        topRight: 4,
        topLeft: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      borderSkipped: false,
      borderWidth: {
        top: 1,
      },
      borderColor: 'transparent',
    },
    {
      label: 'High',
      afterTitle: 'IP Reputation',
      data: [100, 0, 50, 25, 0, 0, 0, 0],
      backgroundColor: '#109E10',
      barThickness: 16,
      barPercentage: 1,
      categoryPercentage: 0.5,
      borderRadius: {
        topRight: 4,
        topLeft: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      borderSkipped: false,
      borderWidth: {
        top: 1,
      },
      borderColor: 'transparent',
    },
  ],
};

const StackedBarChart = () => {
  return (
    <>
      <Bar data={data} options={StackedBarOptions} />
    </>
  );
};

export default StackedBarChart;
