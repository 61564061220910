import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ToasterCloseIcon, ToasterPrefix } from '../../icons';
import ButtonElement from '../Button/Button';

function ToasterElement({ prefix, closable, content = 'Message' }) {
  const [showToaster, setShowToaster] = useState(false);
  const hideTimeoutRef = useRef(null);

  const handleButtonClick = () => {
    setShowToaster(true);
    setHideTimeout();
  };

  const setHideTimeout = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    hideTimeoutRef.current = setTimeout(() => {
      setShowToaster(false);
    }, 2000);
  };

  const handleMouseOver = () => {
    clearTimeout(hideTimeoutRef.current);
  };

  const handleMouseOut = () => {
    setHideTimeout();
  };

  const toasterContent = (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={`toaster-content relative right-[-372px] duration-400 ease-in-out flex items-center justify-between gap-[var(--emt-space-300)] min-w-[var(--emt-size-8200)] py-[var(--emt-space-400)] px-[var(--emt-space-500)] bg-[var(--emt-background-info)] rounded-md !transition-all mb-4 ${showToaster ? '!right-[24px] !transition-all' : ''
        }`}
    >
      <div className="flex items-center gap-[var(--emt-space-300)]">
        {prefix && (
          <span>
            <ToasterPrefix />
          </span>
        )}
        <h6 className="text-[var(--emt-text-on-bg-fill)] font-normal text-lg">
          {content}
        </h6>
      </div>
      {closable && (
        <span onClick={() => setShowToaster(false)} className="cursor-pointer">
          <ToasterCloseIcon />
        </span>
      )}
    </div>
  );

  useEffect(() => {
    const toasterContainer =
      document.querySelector('.toaster-container') || createContainer();
    return () => {
      if (toasterContainer && toasterContainer.parentNode) {
        toasterContainer.parentNode.removeChild(toasterContainer);
      }
      clearTimeout(hideTimeoutRef.current);
    };
  }, []);

  const createContainer = () => {
    const div = document.createElement('div');
    div.className = 'toaster-container';
    div.style.position = 'fixed';
    div.style.top = '24px';
    div.style.right = '24px';
    document.body.appendChild(div);
    return div;
  };

  return (
    <>
      <ButtonElement onPress={handleButtonClick} text="Show Toaster" />
      {showToaster &&
        ReactDOM.createPortal(
          toasterContent,
          document.querySelector('.toaster-container')
        )}
    </>
  );
}

export default ToasterElement;
