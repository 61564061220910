const HoverLine = {
  id: 'hoverLine',

  afterDraw: (chart, args, options) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      const x = chart.tooltip._active[0].element.x;
      const y = chart.tooltip._active[0].element.y;
      const bottomY = chart.scales.y.bottom;
      const circleRadius = 7;
      const circleColor = 'rgba(15, 95, 204, 1)';

      ctx.save();
      // Draw the line
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = circleColor;
      ctx.stroke();

      // Draw the circle
      ctx.beginPath();
      ctx.arc(x, y, circleRadius, 0, 2 * Math.PI, false);
      ctx.fillStyle = circleColor;
      ctx.fill();

      ctx.restore();
    }
  }
}

export default HoverLine;