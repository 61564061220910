import ExternalTooltipHandler from "./ExternalTooltipHandler";

const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      beginAtZero: true,
      border: {
        dash: [5, 5],
        color: "transparent",
      },
      grid: {
        color: "#DCDFE4",
        tickColor: "transparent"
      },
      title: {
        display: true,
        text: "Reputation group",
        font: {
          family: 'Manrope',
          weight: '500',
          size: 12,
          lineHeight: '14.4px',
        },
        color: "#0C0D10",
        padding: {
          bottom: 16
        }
      },
      ticks: {
        beginAtZero: true,
        stepSize: 25,
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        },
        callback: function (value) {
          if (value < 25 && value >= 0) return 'Bad';
          else if (value >= 25 && value < 50) return 'Low';
          else if (value >= 50 && value < 75) return 'Medium';
          else if (value >= 75 && value < 100) return 'High';
        }
      }
    },
    x: {
      beginAtZero: true,
      border: {
        color: "#DCDFE4"
      },
      grid: {
        offset: false,
        drawOnChartArea: false,
        tickColor: "#DCDFE4",
        tickLength: 6,
        tickWidth: 1,
      },
      ticks: {
        align: 'center',
        padding: 8,
        callback: function (value) {
          return this.getLabelForValue(value).map((label, index) => index === 1 ? label.substr(0, 3) : label);
        },
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        }
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: ExternalTooltipHandler,
      callbacks: {
        title: (context) => {
          return context[0].label.replaceAll(',', ' ');
        }
      }
    },
    legend: {
      display: false
    }
  }
}

export default lineChartOptions