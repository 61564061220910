import React, { useState } from 'react'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { TreeClose, TreeOpen } from '../../icons';
import './tree.scss'

export const TreeElement = () => {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const nodes = [{
    value: 'Entertainments',
    label: 'Entertainments',
    children: [
      { value: 'photo', label: 'photo' },
      { value: 'videos', label: 'videos' },
      { value: 'songs', label: 'songs' },
      { value: 'movies', label: 'movies' },
    ],
  }];
  const handleCheck = (checked) => {
    setChecked(checked);
  };

  const handleExpand = (expanded) => {
    setExpanded(expanded);
  };
  return (
    <div className="mail-tree w-full">
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={handleCheck}
        onExpand={handleExpand}
        nativeCheckboxes
        checkModel={"all"}
        icons={{
          expandClose: <TreeClose />,
          expandOpen: <TreeOpen />,
        }}
      />
    </div>
  );
}
