const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.reputation-group-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('reputation-group-tooltip');
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .2s ease';
    tooltipEl.style.minWidth = '94px';
    tooltipEl.style.width = '100%';
    tooltipEl.style.maxWidth = '100px';
    const tooltipTable = document.createElement('table');
    tooltipTable.classList.add('tooltip-table');

    tooltipEl.appendChild(tooltipTable);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};


const ExternalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      const th = document.createElement('th');
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const valueRegex = /(\d+(?:\.\d+)?)/;
      const value = body.join('').match(valueRegex)[0];
      const tr = document.createElement('tr');
      const td = document.createElement('td');
      const span = document.createElement('span');

      function valueTypeFormatter(value) {
        if (value < 25 && value >= 0) return 'Bad';
        else if (value >= 25 && value < 50) return 'Low';
        else if (value >= 50 && value < 75) return 'Medium';
        else if (value >= 75 && value < 100) return 'High';
      }

      const valueText = document.createTextNode(valueTypeFormatter(value));
      span.appendChild(valueText);
      td.appendChild(span);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove previous children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const tooltipWidth = tooltipEl.offsetWidth;
  const tooltipHeight = tooltipEl.offsetHeight;
  const tipPositionLeft = 18;

  let posX = positionX + tooltip.caretX;
  let posY = positionY - tooltipHeight + tooltip.caretY - 15;

  if ((posX + tooltipWidth) > window.innerWidth) {
    posX = posX - (tooltipWidth / 2) + (tipPositionLeft);
    tooltipEl.classList.add('tooltip-right');
  } else {
    tooltipEl.classList.remove('tooltip-right');
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = posX + 'px';
  tooltipEl.style.top = posY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

export default ExternalTooltipHandler;