export const EmptyStateSnippet = {
  normal: {
    key: '1',
    code: `
import EmptyState from '@email-tracker';
    
const Element = () => {
    
  return (
    <EmptyState />
  );
};
    
export default Element;
  `.trim()
  }

}