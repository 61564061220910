const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.user-report-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('user-report-tooltip');
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .2s ease';
    tooltipEl.style.minWidth = '255px';
    tooltipEl.style.width = '100%';
    tooltipEl.style.maxWidth = '255px';
    const tooltipTable = document.createElement('table');
    tooltipTable.classList.add('tooltip-table');

    tooltipEl.appendChild(tooltipTable);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const createTextarea = (chart) => {
  let container = chart.canvas.parentNode.querySelector('.textarea-container');

  if (!container) {
    // creating textarea container
    container = document.createElement('div');
    container.classList.add('textarea-container');
    container.style.opacity = 1;
    container.style.position = 'absolute';
    container.style.transform = 'translate(-50%, 0)';
    container.style.transition = 'all .2s ease';
    container.style.zIndex = '999';

    // Creating textarea within the container
    let textareaEl = document.createElement('textarea');
    textareaEl.classList.add('tooltip-comment');
    textareaEl.style.transition = 'all .2s ease';
    textareaEl.style.width = '100%';
    textareaEl.style.zIndex = '999';
    textareaEl.placeholder = 'Comment here...';

    // creating send button
    let sendButton = document.createElement('button');
    sendButton.classList.add('send-button');
    sendButton.innerHTML = `
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1571_40121)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.02054 1.85277C1.95542 1.59267 2.04807 1.3188 2.25719 1.15098C2.46631 0.983165 2.75395 0.952653 2.99358 1.07247L9.62071 4.38603C9.85327 4.50213 10.0003 4.7399 10.0003 5C10.0003 5.2601 9.85327 5.49787 9.62071 5.61397L2.99358 8.92753C2.75395 9.04735 2.46631 9.01683 2.25719 8.84902C2.04807 8.6812 1.95542 8.40733 2.02054 8.14723L2.71451 5.3721L6.74436 5L2.71451 4.6279L2.02054 1.85277Z" fill="#F1F4F8"/>
      </g>
      <defs>
        <clipPath id="clip0_1571_40121">
          <rect width="10" height="10" fill="white"/>
        </clipPath>
      </defs>
    </svg>`.trim();

    container.appendChild(textareaEl);
    container.appendChild(sendButton);
    chart.canvas.parentNode.appendChild(container);
    textareaEl.removeEventListener('focus', focusHandler);

    function focusHandler() {
      container.classList.add('expand');
      const tooltipEl = container.previousSibling;
      const currTooltipHeight = tooltipEl.clientHeight;
      const newTooltipHeight = currTooltipHeight + this.clientHeight;

      if (newTooltipHeight <= 195) {
        tooltipEl.style.height = newTooltipHeight + 'px';
      }
    }

    sendButton.addEventListener('click', () => {
    })

    textareaEl.addEventListener('focus', focusHandler);
  }

  return container;
}


const ExternalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const textareaEl = createTextarea(chart);

  if (tooltip.opacity === 0) {
    // tooltipEl.style.opacity = 0;
    // textareaEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const comments = '(5 comments)';
      const tr = document.createElement('tr');
      const th = document.createElement('th');
      const trSpan = document.createElement('span');
      const text = document.createTextNode(title);
      const spanText = document.createTextNode(comments);

      th.appendChild(text);
      trSpan.appendChild(spanText);
      tr.appendChild(th);
      tr.appendChild(trSpan);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const valueRegex = /(\d+(?:\.\d+)?)/;
      const labelRegex = /^(.*?):/;
      const value = body.join('').match(valueRegex)[0];
      const label = body.join('').match(labelRegex)[0];
      const tr = document.createElement('tr');
      const td = document.createElement('td');
      const span = document.createElement('span');
      const text = document.createTextNode(label);
      const valueText = document.createTextNode(value + '%');
      span.appendChild(valueText);
      td.appendChild(text);
      td.appendChild(span);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove previous children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const { y: tooltipY, } = tooltipEl.getBoundingClientRect();
  const tableRoot = tooltipEl.querySelector('table');
  const { y: tableY, height: tableHeight } = tableRoot.getBoundingClientRect();
  const { height: textareaHeight } = textareaEl.getBoundingClientRect();

  const tooltipWidth = tooltipEl.offsetWidth;
  const tooltipHeight = tooltipEl.offsetHeight;
  const tipPositionLeft = 18;

  let posX = positionX + (tooltipWidth / 2) + tooltip.caretX - tipPositionLeft;
  let posY = positionY - tooltipHeight + tooltip.caretY - 20;

  if ((posX + tooltipWidth + 50) > window.innerWidth) {
    posX = posX - tooltipWidth + (tipPositionLeft * 2);
    tooltipEl.classList.add('tooltip-right');
  } else {
    tooltipEl.classList.remove('tooltip-right');
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = posX + 'px';
  tooltipEl.style.top = posY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.height = tableHeight + textareaHeight + 24 + 16 + 'px';

  // textareaEl position
  textareaEl.style.opacity = 1;
  textareaEl.style.left = posX + 'px';
  textareaEl.style.top = posY + (tableY - tooltipY) + tableHeight + 16 + 'px';
  textareaEl.style.width = tooltipWidth - 28 + 'px';
};

export default ExternalTooltipHandler;