import React from 'react'
import { TreeElement } from '../theme/Components/Tree/Tree'
import CompLayout from '../theme/Layout/CompLayout'

export default function TreeComp() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page"
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <TreeElement />
        }
        title="Normal"
        desc={commonDesc}
      // code={ToasterSnippet.normal.code}
      />
    </div>
  )
}
