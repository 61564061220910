import React from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
} from '@nextui-org/react';
import './table.scss';
import PaginationElement from '../Pagination/Pagination';
function TableElement({
  rows,
  columns,
  defaultSelectedKeys = ['2', '3'],
  multiple,
  pagination,
}) {
  return (
    <Table
      selectionMode={multiple && 'multiple'}
      defaultSelectedKeys={multiple ? defaultSelectedKeys : ''}
      aria-label="Example table with dynamic content"
      checkboxesProps={{
        classNames: {
          base: 'flex p-0 gap-[var(--emt-space-200)] mail-checkbox ',
          wrapper:
            'mail-checkbox-wrapper before:border-2  before:border-[var(--emt-background-tertiary)] w-[var(--emt-space-500)] h-[var(--emt-space-500)] before:rounded-sm rounded-sm mr-0 hover:border-[var(--emt-border-selected)] after:rounded-sm',
          label: 'text-[var(--emt-text-primary)] font-medium text-lg',
        },
      }}
      classNames={{
        wrapper: 'p-0 rounded-lg gap-0 shadow-none',
        thead: "mail-table-head",
        th: "h-auto py-[var(--emt-space-250)] px-[var(--emt-space-600)] bg-[var(--emt-background-secondary)] !rounded-none border-b-1 border-[var(--emt-border-primary)] hover:bg-[var(--emt-background-surface-hover)] text-[var(--emt-text-tertiary)] font-semibold text-xs",
        td: "bg-[var(--emt-background-secondary)] !outline-none hover:bg-[var(--emt-background-surface-hover)] text-[var(--emt-text-primary)] font-semibold text-lg",
        tbody: "bg-red-900 mail-table-body"
      }}
      bottomContent={pagination && <PaginationElement />}
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={rows}>
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => (
              <TableCell>{getKeyValue(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default TableElement;
