import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout';
import ButtonElement from './../../theme/Components/Button/Button';
import { ButtonSnippet } from '../../theme/Components/Button/CodeSnippet';

function WhiteButton() {
  const buttonSizes = [
    { size: 'lg', title: 'large', desc: "Variant of button with a large container and white background fill" },
    { size: 'md', title: 'normal', desc: "The default version of the white button, where the background fill is white" },
    { size: 'sm', title: 'small', desc: "Variant of button with a small container and white background fill" },
    { size: 'lg', title: 'fluid', desc: "Variant of button with a flexible container that resizes based on the size of the viewport and white background fill", fluid: true },
    { size: 'lg', title: 'disabled', desc: "Variant of button that is faded out and signifies to the user that it cannot be accessed yet", disabled: true },
  ];
  return (
    <div>
      <h3 className="section-title">White</h3>
      <p className="comp-desc">
        Type of button with a white background fill and black label
      </p>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        {buttonSizes.map(({ size, title, desc, fluid, disabled }, i) => (
          <CompLayout
            key={title}
            component={<ButtonElement size={size} variant="white" fluid={fluid} disabled={disabled} />}
            title={title}
            desc={desc}
            code={ButtonSnippet.white[i].code}
          />
        ))}
      </div>
    </div>
  )
}

export default WhiteButton