import React, { useState } from 'react';
import CompLayout from '../theme/Layout/CompLayout';
import DrawerElement from '../theme/Components/Drawer/Drawer';
import ButtonElement from './../theme/Components/Button/Button';
import { DrawerSnippet } from '../theme/Components/Drawer/CodeSnippet';
import RadioElement from '../theme/Components/Radio/Radio';

function DrawerComp() {
  const [drawerState, setDrawerState] = useState({
    drawer1: false,
    drawer2: false,
    drawer3: false,
  });

  const [position, setPosition] = useState("right")

  const toggleDrawer = (drawerKey) => {
    setDrawerState(prevState => ({
      ...prevState,
      [drawerKey]: !prevState[drawerKey]
    }));
  };

  const positionData = [
    {
      value: 'top',
      label: 'Top'
    }, {
      value: 'bottom',
      label: 'Bottom'
    },
    {
      value: 'right',
      label: 'Right'
    }, {
      value: 'left',
      label: 'Left'
    }
  ]

  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <>
            <ButtonElement text="Open Drawer" onPress={() => toggleDrawer('drawer1')} />
            <DrawerElement prefix isOpen={drawerState.drawer1} setOpen={() => toggleDrawer('drawer1')}>
              <div className="flex flex-col gap-2">
                <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
                <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
              </div>
            </DrawerElement>
          </>
        }
        title="Normal"
        desc="A drawer consists of less significant content, and sits at the edge of the design until the user chooses to open it"
        code={DrawerSnippet.normal.code}
      />
      <CompLayout
        component={
          <>
            <ButtonElement text="Open Drawer" onPress={() => toggleDrawer('drawer2')} />
            <DrawerElement size="lg" prefix isOpen={drawerState.drawer2} setOpen={() => toggleDrawer('drawer2')}>
              <div className="flex flex-col gap-2">
                <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
                <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
              </div>
            </DrawerElement>
          </>
        }
        title="large"
        desc="Variant of the drawer that occupies more space on the screen and can contain more elements"
        code={DrawerSnippet.large.code}
      />
      <CompLayout
        component={
          <>
            <div className="flex flex-col gap-5">
              <RadioElement value={position} onChange={setPosition} radioData={positionData} orientation="horizontal" />
              <ButtonElement text="Open Drawer" onPress={() => toggleDrawer('drawer3')} />
            </div>
            <DrawerElement position={position} prefix isOpen={drawerState.drawer3} setOpen={() => toggleDrawer('drawer3')}>
              <div className="flex flex-col gap-2">
                <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
                <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
              </div>
            </DrawerElement>
          </>
        }
        title="positions"
        desc="Radio buttons allow the user to select which side the drawer should slide in from"
        code={DrawerSnippet.position.code}
      />
    </div>
  );
}

export default DrawerComp;
