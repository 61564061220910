export const SearchSnippet = {
  normal: {
    key: '1',
    code:
      `import Search from '@email-tracker'

const Element = () =>{

return(
  <Search />
)

export default Element`
  },
  keyBoard: {
    key: '2',
    code:
      `import Search from '@email-tracker'

const Element = () =>{

return(
  <Search keyboard />
)

export default Element`
  },
  borderLess: {
    key: '3',
    code:
      `import Search from '@email-tracker'

const Element = () =>{

return(
  <Search borderLess />
)

export default Element`
  },
  transparent: {
    key: '4',
    code:
      `import Search from '@email-tracker'

const Element = () =>{

return(
  <Search keyboard borderLess transparent />
)

export default Element`
  },
}