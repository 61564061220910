import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import ToggleElement from '../theme/Components/Toggle/Toggle'
import { ToggleSnippet } from '../theme/Components/Toggle/CodeSnippet'

function ToggleComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <ToggleElement />
        }
        title="Normal"
        desc="Control for switching between two options"
        code={ToggleSnippet.normal.code}
      />
    </div>
  )
}

export default ToggleComp