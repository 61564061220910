import React from 'react'
import { Informative } from '../components/Alert/Informative'
import { Caution } from '../components/Alert/Caution'
import { Success } from '../components/Alert/Success'
import { Danger } from '../components/Alert/Danger'

function AlertComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <Informative />
      <Caution />
      <Success />
      <Danger />
    </div>
  )
}

export default AlertComp