export const CheckboxSnippet = {
  normal: {
    key: '1',
    code: `
import CheckboxElement from '@email-tracker';

const Element = () => {

  return (
    <CheckboxElement />
  );
};
    
    export default Element;
`.trim()
  },
  label: {
    key: '1',
    code: `
import CheckboxElement from '@email-tracker';

const Element = () => {
  
  return (
    <CheckboxElement>Option</CheckboxElement>
  );
};

export default Element;
`.trim()
  },
  indeterminate: {
    key: '1',
    code: `
import CheckboxElement from '@email-tracker';

const Element = () => {
  
  return (
    <CheckboxElement indeterminate />
  );
};

export default Element;
`.trim()
  }
};
