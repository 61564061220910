import React from 'react';
import { Tooltip } from '@nextui-org/react';
import './tooltip.scss';
function TooltipElement({
  children,
  content = 'This is tooltip',
  arrow,
  placement,
  isOpen,
  setOpen = () => { },
}) {
  return (
    <Tooltip
      isOpen={isOpen}
      onOpenChange={(open) => setOpen(open)}
      placement={placement}
      showArrow={arrow}
      content={content}
      classNames={{
        base: 'mail-tooltip',
        content:
          'p-[var(--emt-space-300)] bg-[var(--emt-background-info)] rounded-md text-[var(--emt-text-on-bg-fill)]',
      }}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipElement;
