import React from 'react'
import { Avatar } from "@nextui-org/react";

function AvatarElement({ src, key, className }) {
  return (
    <Avatar key={key} src={src} classNames={{
      base: `rounded-full w-[var(--emt-space-1000)] h-[var(--emt-space-1000)] cursor-pointer ${className}`,
    }} />
  )
}

export default AvatarElement