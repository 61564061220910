import React from 'react'
import { RadioGroup, Radio } from "@nextui-org/react";
import './radio.scss'
function RadioElement({ radioData, orientation, value, onChange }) {

  return (
    <RadioGroup
      orientation={orientation}
      value={value}
      onValueChange={onChange}
    >
      {
        radioData.map((item, i) => {
          return (
            <Radio key={i} classNames={{
              base: `mail-radio ${item.disabled && "disabled"}`,
              wrapper: `mail-radio-wrapper border-[var(--emt-border-tertiary)] h-[var(--emt-space-500)] w-[var(--emt-space-500)] ${item.disabled && "border-[var(--emt-background-disabled)]"}`
            }} value={item.value}>{item.label}</Radio>
          )
        })
      }
    </RadioGroup>

  )
}

export default RadioElement