import React from 'react'
import { Card, CardBody } from "@nextui-org/react";
function CardElement({
  size = "normal", children, bg, height, width
}) {
  let cardSize;
  switch (size) {
    case "lg":
      cardSize = "p-[var(--emt-space-600)] w-[var(--emt-size-8200)]"
      break;
    case "sm":
      cardSize = "p-[var(--emt-space-400)] w-[var(--emt-size-8200)]"
      break;
    default:
      cardSize = "p-[var(--emt-space-500)] w-[var(--emt-size-8200)]"
      break;
  }

  let cardBg;
  switch (bg) {
    case "white":
      cardBg = "bg-[var(--emt-background-surface)] border-transparent"
      break;
    case "dark":
      cardBg = "border-transparent bg-[var(--emt-background-emphasis)] !text-white"
      break;
    case "light":
      cardBg = "border-transparent bg-[var(--emt-background-discovery)]"
      break;
    case "stroke":
      cardBg = "bg-transparent border-[var(--emt-border-secondary)]"
      break;
    default:
      cardBg = "bg-[var(--emt-background-primary)] border-transparent"
      break;
  }
  return (
    <Card className={`shadow-none ${cardBg} w-${width} h-${height} text-[var(--emt-text-primary)] rounded-lg border `} >
      <CardBody className={`${cardSize}`}>
        {children}
      </CardBody>
    </Card>
  )
}

export default CardElement