import ExternalTooltipHandler from "./ExternalTooltipHandler";

const StraightLineChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: 'index',
  },
  scales: {
    y: {
      border: {
        dash: [5, 5],
        color: "transparent",
      },
      grid: {
        color: "#DCDFE4",
        tickColor: "transparent"
      },
      title: {
        display: true,
        text: "Volume of TLS encrypted traffic",
        font: {
          family: 'Manrope',
          weight: '500',
          size: 12,
          lineHeight: '14.4px',
        },
        color: "#0C0D10",
        padding: {
          bottom: 16
        }
      },
      min: 0,
      ticks: {
        beginAtZero: true,
        stepSize: 25,
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        },
        callback: function (value) {
          return value + ' %';
        }
      }
    },
    x: {
      beginAtZero: true,
      border: {
        width: 1,
        color: "#DCDFE4"
      },
      grid: {
        drawOnChartArea: false,
        color: "#DCDFE4",
        tickLength: 6,
        tickWidth: 1,
      },
      ticks: {
        align: 'start',
        padding: 8,
        callback: function (value) {
          return this.getLabelForValue(value).substr(0, 6);
        },
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        }
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: ExternalTooltipHandler,
      itemSort: function (a, b) {
        return a.raw - b.raw;
      },
      callbacks: {
        title: (context) => {
          return context[0].label.replaceAll(',', ' ');
        },
        afterTitle: (context) => {
          return context[0].dataset.afterTitle;
        }
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        color: "#4E4E4E",
        font: {
          family: 'Manrope',
          weight: '500',
          size: 14,
          lineHeight: '16.8px',
        },
        padding: 32,
        pointStyle: 'line',
        usePointStyle: true,
        pointStyleWidth: 14,
      }
    }
  }
}

export default StraightLineChartOptions