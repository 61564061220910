import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import Avatar from '../theme/Components/Avatar/Avatar'
import { AvatarSnippet } from '../theme/Components/Avatar/CodeSnippet'
import AvatarGroupElement from '../theme/Components/Avatar/AvatarGroup'

function AvatarComp() {
  const AvatarGroup = [
    {
      key: 1,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 2,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 3,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 4,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 5,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 6,
      src: "/images/svg/avatar.svg"
    },
  ]
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <Avatar src={'/images/svg/avatar.svg'} />
        }
        title="Normal"
        desc="PIdentities of people are indicated with an image in a circle"
        code={AvatarSnippet.normal.code}
      />
      <CompLayout
        component={
          <AvatarGroupElement data={AvatarGroup} />
        }
        title="avatar group"
        desc="To represent a group of people, their avatars appear stacked together"
        code={AvatarSnippet.group.code}
      />
    </div>
  )
}

export default AvatarComp