export const AlertSnippet = {
  information: [
    {
      key: '1',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert prefix />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert action />>
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert closable={false} />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert prefix action />
  )
}

export default Element;`.trim()
    },
  ],
  caution: [
    {
      key: '1',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='caution' />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='caution' prefix />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='caution' action />>
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='caution' closable={false} />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='caution' prefix action />
  )
}

export default Element;`.trim()
    },
  ],
  success: [
    {
      key: '1',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='success' />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='success' prefix />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='success' action />>
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='success' closable={false} />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='success' prefix action />
  )
}

export default Element;`.trim()
    },
  ],
  danger: [
    {
      key: '1',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='danger' />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='danger' prefix />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='danger' action />>
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='danger' closable={false} />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Alert from '@email-tracker';

const Element = () => {

  return (
    <Alert variant='danger' prefix action />
  )
}

export default Element;`.trim()
    },
  ],
};
