export const TooltipSnippet = {
  Normal: {
    key: '1',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Bottom left arrow": {
    key: '2',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="top-start" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Bottom center arrow": {
    key: '3',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="top" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },




  "Bottom right arrow": {
    key: '4',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="top-end" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Top left arrow": {
    key: '5',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="bottom-start" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Top center arrow": {
    key: '6',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="bottom" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Top right arrow": {
    key: '7',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="bottom-end" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Left top arrow": {
    key: '8',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="right-start" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Left center arrow": {
    key: '9',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="right" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Left bottom arrow": {
    key: '10',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="right-end" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Right top arrow": {
    key: '11',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="left-start" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Right center arrow": {
    key: '12',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="left" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
  "Right bottom arrow": {
    key: '13',
    code:
      `
import { TooltipElement,ButtonElement } from '@email-tracker';

const Element = () => {

  return (
    <TooltipElement placement="left-end" arrow>
      <ButtonElement text="Hover Me"/>
    </TooltipElement>
  )
}

export default Element;`.trim()
  },
}