import React, { useState } from 'react';
import { Textarea } from '@nextui-org/react';
import { useRef } from 'react';
import './chips.scss';
import { DangerousInfoIcon } from '../../icons';
import TagElement from '../Tag/Tag';

const Chips = ({ label = 'Label', disabled = false, readOnly = false }) => {
  const [value, setValue] = useState('');
  const [tags, setTags] = useState(['Basic tag']);
  const inputRef = useRef(null);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setValue(e.target.value.slice(0, 20));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ",") {
      if (value !== '' && value.trim() && !tags.includes(value)) {
        e.preventDefault();
        setTags((prevValue) => [...prevValue, value]);
        setValue('');
        setError('');
      } else {
        e.preventDefault();
        validateInput(value);
      }
    } else if (e.key === 'Backspace' && !value) {
      setTags((prev) => prev.slice(0, -1));
    } else if (e.key === 'Enter') {
      if (e.shiftKey) {
        setValue(e.target.value + '\n');
      }
    }
  };

  const validateInput = (inputValue) => {
    if (inputValue.trim().length < 3) {
      setError('Input must be at least 3 characters long.');
    } else if (!inputValue) {
      setError('This field is required.');
    } else {
      setError('');
    }
  };

  const renderChip = (chipData) => (
    <>
      {chipData.map((chip, i) => (
        <TagElement
          closable
          disabled={disabled || readOnly}
          key={i}
          onClose={() => {
            if (readOnly) return;
            setTags(tags.filter((_, index) => index !== i));
          }}
        >
          <span>{chip}</span>
        </TagElement>
      ))}
    </>
  );

  return (
    <>
      <div className="relative w-full flex flex-col flex-1 gap-[var(--emt-space-200)]">
        {label.trim() && (
          <label
            htmlFor="chips"
            className={`w-full inline-flex ${disabled
              ? 'text-text-disabled'
              : 'text-[color:var(--emt-text-secondary)]'
              } font-[number:var(--emt-font-weight-medium)] text-[length:var(--emt-font-size-200)] leading-[var(--emt-line-height-120)] tracking-[var(--emt-font-letter-spacing-normal)] normal-case`}
          >
            {label}
          </label>
        )}
        <div
          className={`flex w-full flex-wrap gap-2 pt-[var(--emt-space-200)] pr-[var(--emt-space-300)] pb-[var(--emt-space-200)] pl-[var(--emt-space-300)] bg-[color:var(--emt-background-surface)] border-[length:var(--emt-border-width-100)] hover:border-[var(--emt-border-input-active)] border-[color:var(--emt-border-input)] focus-within:border-[color:var(--emt-border-input-active)] border-solid rounded-[var(--emt-border-radius-100)] transition-all duration-200 ${readOnly ? '!border-[var(--emt-border-input-active)] !bg-[color:var(--emt-background-disabled)]' : ''
            } ${disabled && "!border-[var(--emt-border-disabled)]"}`}
        >
          {renderChip(tags)}
          <Textarea
            id="chips"
            ref={inputRef}
            className={`textarea-chips flex-[1_1_0] [&_textarea]:text-[color:var(--emt-text-primary)] ${disabled
              ? '[&_textarea]:placeholder-text-disabled'
              : '[&_textarea]:placeholder-text-placeholder'
              } ${readOnly
                ? '[&_textarea]:bg-transparent]'
                : ''
              }  [&_textarea]:font-[number:var(--emt-font-weight-regular)] [&_textarea]:text-[length:var(--emt-font-size-300)] [&_textarea]:leading-[var(--emt-line-height-150)] [&_textarea]:tracking-[var(--emt-font-letter-spacing-normal)] normal-case`}
            placeholder="Value"
            value={value}
            onChange={handleInputChange}
            onFocus={() => (inputRef.current.style.outline = 'none')}
            onKeyDown={handleKeyPress}
            clearable
            minRows={7}
            readOnly={readOnly}
            disabled={disabled}
          />
        </div>
        <div className="flex">
          <span
            className={`w-full inline-flex ${disabled
              ? 'text-text-disabled pointer-events-none'
              : 'text-[color:var(--emt-text-tertiary)]  '
              } font-[number:var(--emt-font-weight-regular)] text-[length:var(--emt-font-size-200)] tracking-[var(--emt-font-letter-spacing-normal)] normal-case`}
          >
            {error && (
              <span className="inline-flex items-center gap-1">
                <DangerousInfoIcon />
                <span className="text-[color:var(--emt-text-negative)] text-[length:var(--emt-font-size-200)] leading-[var(--emt-line-height-150)] tracking-[var(--emt-font-letter-spacing-normal)] normal-case">
                  Helpful validation message
                </span>
              </span>
            )}
          </span>
          <span
            className={`inline-flex ${disabled
              ? 'text-text-disabled pointer-events-none'
              : 'text-[color:var(--emt-text-tertiary)]  '
              } font-[number:var(--emt-font-weight-regular)] text-[length:var(--emt-font-size-200)] tracking-[var(--emt-font-letter-spacing-normal)] normal-case`}
          >
            {value.length}/20
          </span>
        </div>
      </div>
    </>
  );
};

export default Chips;
