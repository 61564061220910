export const ModalSnippet = {
  normal: {
    key: '1',
    code:
      `
import ModalElement from '@email-tracker';

const Element = () => {
      
  return (
    <ModalElement heading="Modal Heading">
      <div className="flex items-center flex-col gap-2">
         <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
         <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
       </div>
    </ModalElement>
  )
      
    export default Element;`.trim()
  },
  prefix: {
    key: '2',
    code:
      `
import ModalElement from '@email-tracker';

const Element = () => {
      
  return (
    <ModalElement prefix heading="Modal Heading">
      <div className="flex items-center flex-col gap-2">
         <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
         <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
       </div>
    </ModalElement>
  )
      
    export default Element;`.trim()
  },
  footer: {
    key: '3',
    code:
      `
import ModalElement from '@email-tracker';

const Element = () => {
      
  return (
    <ModalElement footer={
      <ButtonElement fluid />
    } heading="Modal Heading">
      <div className="flex items-center flex-col gap-2">
         <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
         <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
       </div>
    </ModalElement>
  )
      
    export default Element;`.trim()
  },
  withoutHeader: {
    key: '4',
    code:
      `
import ModalElement from '@email-tracker';

const Element = () => {
      
  return (
    <ModalElement heading={null}>
      <div className="flex items-center flex-col gap-2">
         <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
         <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
       </div>
    </ModalElement>
  )
      
    export default Element;`.trim()
  },
  withoutFooter: {
    key: '5',
    code:
      `
import ModalElement from '@email-tracker';

const Element = () => {
      
  return (
    <ModalElement footer={false} heading="Modal Heading">
      <div className="flex items-center flex-col gap-2">
         <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
         <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
       </div>
    </ModalElement>
  )
      
    export default Element;`.trim()
  },
  withoutCloseIcon: {
    key: '6',
    code:
      `
import ModalElement from '@email-tracker';

const Element = () => {
      
  return (
    <ModalElement closable={false} heading="Modal Heading">
      <div className="flex items-center flex-col gap-2">
         <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
         <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
       </div>
    </ModalElement>
  )
      
    export default Element;`.trim()
  },
}