import React, { useState } from 'react'
import { DatePickerElement } from '../theme/Components/DatePicker/DatePicker';
import CompLayout from '../theme/Layout/CompLayout';
import { DatePickerSnippet } from '../theme/Components/DatePicker/CodeSnippet';

function DatePickerComp() {
  const [value, onChange] = useState(new Date());
  return (
    <div className="flex flex-col gap-[52px]">
      <CompLayout
        component={
          <DatePickerElement value={value} onChange={onChange} />
        }
        title="Normal"
        desc="Date picker allows the user to set the the date by presenting a calendar to choose from"
        code={DatePickerSnippet.normal.code}
      />
    </div>
  )
}

export default DatePickerComp