export const SelectSnippet = {
  normal: {
    key: '1',
    code: `
import SelectElement from '@email-tracker';

const Element = () => {
    
  const SelectData = [
    { key: "1", label: "Option 1", },
    { key: "2", label: "Option 2", },
    { key: "3", label: "Option 3", },
    { key: "4", label: "Option 4", }
  ];
    
  return (
    <SelectElement data={SelectData} />
  );
};
    
export default Element;
  `.trim()
  },
  multiple: {
    key: '2',
    code: `
import SelectElement from '@email-tracker';
    
const Element = () => {
    
  const SelectData = [
    { key: "1", label: "Option 1", },
    { key: "2", label: "Option 2", },
    { key: "3", label: "Option 3", },
    { key: "4", label: "Option 4", }
  ];
    
  return (
    <SelectElement data={SelectData} multiple/>
  );
};
    
export default Element;
  `.trim()
  },
  prefix: {
    key: '3',
    code: `
import SelectElement from '@email-tracker';
    
const Element = () => {
    
  const SelectData = [
    { key: "1", label: "Option 1", },
    { key: "2", label: "Option 2", },
    { key: "3", label: "Option 3", },
    { key: "4", label: "Option 4", }
  ];
    
  return (
    <SelectElement data={SelectData} multiple prefix/>
  );
};
    
export default Element;
  `.trim()
  },
  suffix: {
    key: '4',
    code: `
import SelectElement from '@email-tracker';
    
const Element = () => {
    
  const SelectData = [
    { key: "1", label: "Option 1", },
    { key: "2", label: "Option 2", },
    { key: "3", label: "Option 3", },
    { key: "4", label: "Option 4", }
  ];
    
  return (
    <SelectElement data={SelectData} suffix multiple />
  );
};
    
export default Element;
  `.trim()
  },
  suffixAndPrefix: {
    key: '1',
    code: `
import SelectElement from '@email-tracker';
    
const Element = () => {
    
  const SelectData = [
    { key: "1", label: "Option 1", },
    { key: "2", label: "Option 2", },
    { key: "3", label: "Option 3", },
    { key: "4", label: "Option 4", }
  ];
    
  return (
    <SelectElement data={SelectData} multiple prefix suffix/>
  );
};
    
export default Element;
  `.trim()
  },
}