import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from '../theme/Layout/Layout';
import ButtonComp from '../pages/ButtonComp';
import AvatarComp from '../pages/AvatarComp';
import TooltipComp from '../pages/TooltipComp';
import RadioComp from '../pages/RadioComp';
import DropdownComp from '../pages/DropdownComp';
import TabComp from '../pages/TabComp';
import ToggleComp from './../pages/ToggleComp';
import CheckboxComp from '../pages/CheckboxComp';
import TagComp from '../pages/TagComp';
import PaginationComp from '../pages/PaginationComp';
import SearchComp from '../pages/SearchComp';
import TableComp from './../pages/TableComp';
import GraphComp from '../pages/GraphComp';
import ToasterComp from './../pages/ToasterComp';
import ModalComp from './../pages/ModalComp';
import FormValidationComp from '../pages/FormValidationComp';
import SelectComp from '../pages/SelectComp';
import DrawerComp from './../pages/DrawerComp';
import { EmptyStateComp } from '../pages/EmptyStateComp';
import AlertComp from '../pages/AlertComp';
import CardComp from './../pages/CardComp';
import TreeComp from '../pages/TreeComp';
import NavbarComp from '../pages/NavbarComp';
import DatePickerComp from '../pages/DatePickerComp';
import StepsComp from '../pages/StepsComp';
import ChipsComp from '../pages/ChipsComp';
import InputComp from './../pages/InputComp';
function Router() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Layout title="button">
          <ButtonComp />
        </Layout>
      ),
    },
    {
      path: '/tag',
      element: (
        <Layout title="tag">
          <TagComp />
        </Layout>
      ),
    },
    {
      path: '/avatar',
      element: (
        <Layout title="avatar">
          <AvatarComp />
        </Layout>
      ),
    },
    {
      path: '/tooltip',
      element: (
        <Layout title="tooltip">
          <TooltipComp />
        </Layout>
      ),
    },
    {
      path: '/toggle',
      element: (
        <Layout title="toggle">
          <ToggleComp />
        </Layout>
      ),
    },
    {
      path: '/radio',
      element: (
        <Layout title="radio">
          <RadioComp />
        </Layout>
      ),
    },
    {
      path: '/dropdown',
      element: (
        <Layout title="dropdown">
          <DropdownComp />
        </Layout>
      ),
    },
    {
      path: '/tab',
      element: (
        <Layout title="tab">
          <TabComp />
        </Layout>
      ),
    },
    {
      path: '/pagination',
      element: (
        <Layout title="pagination">
          <PaginationComp />
        </Layout>
      ),
    },
    {
      path: '/search',
      element: (
        <Layout title="search">
          <SearchComp />
        </Layout>
      ),
    },
    {
      path: '/input',
      element: (
        <Layout title="input">
          <InputComp />
        </Layout>
      ),
    },
    {
      path: '/checkbox',
      element: (
        <Layout title="checkbox">
          <CheckboxComp />
        </Layout>
      ),
    },
    {
      path: '/table',
      element: (
        <Layout title="table">
          <TableComp />
        </Layout>
      ),
    },
    {
      path: '/chips',
      element: (
        <Layout title="chips">
          <ChipsComp />
        </Layout>
      ),
    },
    {
      path: "/toaster",
      element: (
        <Layout title="toaster">
          <ToasterComp />
        </Layout>
      ),
    },
    {
      path: "/form",
      element: (
        <Layout title="form">
          <FormValidationComp />
        </Layout>
      ),
    },
    {
      path: "/select",
      element: (
        <Layout title="select">
          <SelectComp />
        </Layout>
      ),
    },
    {
      path: "/modal",
      element: (
        <Layout title="modal">
          <ModalComp />
        </Layout>
      ),
    },
    {
      path: "/drawer",
      element: (
        <Layout title="drawer">
          <DrawerComp />
        </Layout>
      ),
    },
    {
      path: "/empty",
      element: (
        <Layout title="empty">
          <EmptyStateComp />
        </Layout>
      ),
    },
    {
      path: "/alert",
      element: (
        <Layout title="alert">
          <AlertComp />
        </Layout>
      ),
    },
    {
      path: "/card",
      element: (
        <Layout title="card">
          <CardComp />
        </Layout>
      ),
    },
    {
      path: "/navbar",
      element: (
        <Layout title="navbar">
          <NavbarComp />
        </Layout>
      ),
    },
    {
      path: "/datepicker",
      element: (
        <Layout title="date picker">
          <DatePickerComp />
        </Layout>
      ),
    },
    {
      path: "/steps",
      element: (
        <Layout title="steps">
          <StepsComp />
        </Layout>
      ),
    },
    {
      path: "/tree",
      element: (
        <Layout title="tree">
          <TreeComp />
        </Layout>
      ),
    },
    {
      path: "/graph",
      element: (
        <Layout title="graph">
          <GraphComp />
        </Layout>
      ),
    },
  ]);
  return <RouterProvider router={router} />;
}

export default Router;
