export const AvatarSnippet = {
  normal: {
    key: '1',
    code:
      `
import Avatar from '@email-tracker';

const Element = () => {

  return (
    <Avatar src={'/images/svg/avatar.svg'} />
  )
}

export default Element;`.trim()
  },
  group: {
    key: '1',
    code:
      `
import AvatarGroupElement from '@email-tracker';

const Element = () => {
  const AvatarGroup = [
    {
      key: 1,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 2,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 3,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 4,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 5,
      src: "/images/svg/avatar.svg"
    },
    {
      key: 6,
      src: "/images/svg/avatar.svg"
    },
  ]
  
  return (
    <AvatarGroupElement data={AvatarGroup} />
  )
}

export default Element;`.trim()
  },
}