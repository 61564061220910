const StackedPieChartOptions = {
  responsive: true,
  aspectRatio: 2.5,
  cutout: '0%',
  borderWidth: 0,
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        color: "#4E4E4E",
        font: {
          family: 'Manrope',
          weight: '500',
          size: 14,
          lineHeight: '16.8px',
        },
        padding: 16,
        pointStyle: 'circle',
        usePointStyle: true,
        boxWidth: 8,
        boxHeight: 8,
      }
    }
  }
}

export default StackedPieChartOptions