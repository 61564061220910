import React from 'react';
import { Input } from "@nextui-org/react";
import './input.scss'
import { ErrorMessageIcon } from '../../icons';

function InputElement({ id = "input", label = "Label", placeholder = null, isInvalid, errorMessage = "Helpful validation message", ...rest }) {
  return (
    <div className="flex flex-col gap-[var(--emt-space-100)] w-[400px]">
      <label className='text-[var(--emt-text-secondary)] font-medium text-base leading-normal tracking-normal' htmlFor={id}>{label}</label>
      <Input
        {...rest}
        id={id}
        type="email"
        isInvalid={isInvalid}
        errorMessage={
          isInvalid && <div className='flex items-center mt-[var(--emt-space-100)] gap-[var(--emt-space-100)]'>
            <span className=""><ErrorMessageIcon /></span>
            <p className="text-[var(--emt-text-negative)] text-base leading-md font-medium">{errorMessage}</p>
          </div>
        }
        placeholder={placeholder}
        classNames={{
          base: "mail-input",
          inputWrapper: `p-[var(--emt-space-300)] h-auto bg-[var(--emt-background-surface)] hover:border-[var(--emt-border-input-active)] transition rounded-sm border-1 border-[var(--emt-border-input)] ${isInvalid && "!border-[var(--emt-border-negative)]"}`,
          input: `text-[var(--emt-text-primary)] font-normal text-lg leading-normal tracking-normal placeholder:text-[var(--emt-text-placeholder)] ${isInvalid && "!text-[var(--emt-text-negative)]"}`,
          helperWrapper: "p-0"
        }}
      />
    </div>
  );
}

export default InputElement;
