import React from 'react'
import Search from '../theme/Components/Search/Search'
import CompLayout from '../theme/Layout/CompLayout'
import { SearchSnippet } from '../theme/Components/Search/CodeSnippet'

function SearchComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <Search />
        }
        title="Normal"
        desc="Search box lets the user search the platform for specific content or features using key words"
        code={SearchSnippet.normal.code}
      />
      <CompLayout
        component={
          <Search keyboard />
        }
        title="with keyboard suffix"
        desc="Variant of the search box with an icon to indicate that the user can input their search query with a keyboard shortcut"
        code={SearchSnippet.keyBoard.code}
      />
      <CompLayout
        component={
          <Search borderLess />
        }
        title="borderLess"
        desc="Variant of the search box with a greyish fill and no border"
        code={SearchSnippet.borderLess.code}
      />
      <CompLayout
        component={
          <Search keyboard borderLess transparent />
        }
        title="Normal prefix with leading and trailing icon without bg"
        desc="Variant of the search box with no fill, no border and an icon to indicate keyboard shortcut for search"
        code={SearchSnippet.transparent.code}
      />
    </div>
  )
}

export default SearchComp