import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import DropdownElement from '../theme/Components/Dropdown/Dropdown'
import { DropDownSnippet } from '../theme/Components/Dropdown/CodeSnippet';
import ButtonElement from '../theme/Components/Button/Button';

function DropdownComp() {
  const DropData = [
    {
      key: "new",
      label: "New file",
    },
    {
      key: "copy",
      label: "Copy link",
    },
    {
      key: "edit",
      label: "Edit file",
    },
    {
      key: "delete",
      label: "Delete file",
    }
  ];
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <DropdownElement Items={DropData}>
            <ButtonElement text="Open Menu" />
          </DropdownElement>
        }
        title="Normal"
        desc="Interactive menu for selecting options"
        code={DropDownSnippet.normal.code}
      />
    </div>
  )
}

export default DropdownComp