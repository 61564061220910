import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import { Alert } from '../../theme/Components/Alert/Alert'
import { AlertSnippet } from '../../theme/Components/Alert/CodeSnippet';

export const Caution = () => {
  const alertConfigurations = [
    { title: "Normal", component: <Alert variant='caution' />, desc: "The default version of the caution alert, which helps grab the users attention" },
    { title: "with prefix", component: <Alert variant='caution' prefix />, desc: "Variant of a caution alert, it has an icon or label at the start for easy identification" },
    { title: "with Action Buttons", component: <Alert variant='caution' action />, desc: "Variant of a caution alert, it has buttons for the user to take relevant actions based on the cautionary message" },
    { title: "without close button", component: <Alert variant='caution' closable={false} />, desc: "Variant of a caution alert which cannot be closed manually by the user and goes away after a delay" },
    { title: "with prefix and action", component: <Alert variant='caution' prefix action />, desc: "Variant of a caution alert which has an icon or label in the front for easy identification and buttons to help users take actions" }
  ];

  return (
    <div>
      <h3 className="section-title">caution</h3>
      <p className="comp-desc">
        Type of alert which grabs the users attention when state of the system is at risk and has a yellow background.
      </p>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        {alertConfigurations.map(({ title, component, desc }, i) => (
          <CompLayout
            key={i}
            component={component}
            title={title}
            desc={desc}
            code={AlertSnippet.caution[i].code}
          />
        ))}
      </div>
    </div>
  )
}
