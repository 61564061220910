import React from 'react';
import Chart from 'chart.js/auto';
import {
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import barOptions from './BarChartOptions';
import './bar_tooltip.scss';
import './dark_tooltip.scss';
import darkBarOptions from './DarkBarChartOptions';
import HoverLine from './HoverLine';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const data = {
  labels: [
    ['01', 'February'],
    ['02', 'February'],
    ['03', 'February'],
    ['04', 'February'],
    ['05', 'February'],
    ['06', 'February'],
    ['07', 'February'],
    ['08', 'February'],
    ['09', 'February'],
  ],
  datasets: Array.from({ length: 10 }, (_, i) => ({
    label: `User reported spam rate`,
    data: Array.from({ length: 19 }, () =>
      (Math.random() * (3.5 - 2.5) + 2.5).toFixed(1)
    ),
    backgroundColor: '#5E94DD',
    barPercentage: 0.12,
    categoryPercentage: 1.0,
  })),
};

function BarChart({ inputTooltip = false }) {
  let chartOption = darkBarOptions;

  if (inputTooltip) {
    chartOption = barOptions;
  } else {
    chartOption = darkBarOptions;
  }

  return (
    <>
      <Bar data={data} options={chartOption} plugins={[HoverLine]} />
    </>
  );
}

export default BarChart;
