import React from 'react';
import CompLayout from '../theme/Layout/CompLayout';
import PaginationElement from '../theme/Components/Pagination/Pagination';
import { PaginationSnippet } from '../theme/Components/Pagination/CodeSnippet';
import TablePagination from '../theme/Components/TablePagination/TablePagination';
import { useState } from 'react';

function PaginationComp() {
  const [page, setPage] = useState(1);

  return (
    <div className="flex flex-col gap-[52px]">
      <CompLayout
        component={<PaginationElement page={page} setPage={setPage} />}
        title="Normal"
        desc="Pagination is a navigational element that helps one quickly jump to the relevant page with the data"
        code={PaginationSnippet.normal.code}
      />
      <CompLayout
        component={<TablePagination />}
        title="number of rows per page"
        desc="Pagination option where the number of entities to be displayed in each page can be set"
        code={PaginationSnippet.noOfRows.code}
      />
    </div>
  );
}

export default PaginationComp;
