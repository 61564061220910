import React from 'react'
import { Tabs, Tab } from "@nextui-org/react";
import './tab.scss';

function TabElement({ tabData }) {
  return (
    <div className="flex w-full flex-col">
      <Tabs items={tabData} variant="underlined"
        classNames={{
          base: 'mail-tab',
          tabList: 'gap-[var(--emt-space-400)] p-0 mail-tabList',
          tab: 'p-[var(--emt-space-0)] !outline-none h-[27px] items-start',
          tabContent: "px-[var(--emt-space-100)] text-[var(--emt-text-tertiary)] font-semibold transition",
          cursor: 'w-full h-[var(--emt-space-50)] rounded-full bg-[var(--emt-background-fill-selected)] bottom-[-2px]',
          panel: '',
        }}
      >
        {(item) => (
          <Tab key={item.id} title={item.label}>
            {item.content}
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default TabElement