import React from 'react'
import EmptyState from '../theme/Components/EmptyState/EmptyState'
import CompLayout from '../theme/Layout/CompLayout'
import { EmptyStateSnippet } from '../theme/Components/EmptyState/CodeSnippet'

export const EmptyStateComp = () => {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <EmptyState />
        }
        title="Normal"
        desc="State where there is no information to show to the user and is signified with a message or graphic"
        code={EmptyStateSnippet.normal.code}
      />
    </div>
  )
}
