export const ToasterSnippet = {
  normal: {
    key: '1',
    code: `
import ToasterElement from '@email-tracker';
    
const Element = () => {
    
  return (
    <ToasterElement />
  );
};
    
export default Element;
  `.trim()
  },
  prefix: {
    key: '2',
    code: `
import ToasterElement from '@email-tracker';
    
const Element = () => {
    
  return (
    <ToasterElement prefix/>
  );
};
    
export default Element;
  `.trim()
  },
  closable: {
    key: '3',
    code: `
import ToasterElement from '@email-tracker';
    
const Element = () => {
    
  return (
    <ToasterElement closable/>
  );
};
    
export default Element;
  `.trim()
  },
  prefixAndClosable: {
    key: '4',
    code: `
import ToasterElement from '@email-tracker';
    
const Element = () => {
    
  return (
    <ToasterElement prefix closable />
  );
};
    
export default Element;
  `.trim()
  },
}