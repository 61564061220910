import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import TabElement from '../theme/Components/Tab/Tab'
import { TabSnippet } from '../theme/Components/Tab/CodeSnippet';

function TabComp() {
  let tabs = [
    {
      id: "photos",
      label: "Photos",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      id: "music",
      label: "Music",
      content: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    {
      id: "videos",
      label: "Videos",
      content: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    }
  ];
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <TabElement tabData={tabs} />
        }
        title="Normal"
        desc="Organizational element for grouping and navigating content"
        code={TabSnippet.normal.code}
      />
    </div>
  )
}

export default TabComp