import React, { useRef, useEffect, forwardRef } from 'react';
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "prismjs/plugins/line-numbers/prism-line-numbers";

const Snippet = forwardRef(({ code, language = 'javascript', setContent = () => { }, setOpen = () => { } }, ref) => {
  const codeRef = useRef(null);
  useEffect(() => {
    Prism.highlightAll();
  }, [code, language]);

  const copyCodeToClipboard = () => {
    if (codeRef.current) {
      const codeText = codeRef.current.innerText;
      navigator.clipboard
        .writeText(codeText)
        .then(() => {
          setContent("Copied!")
          setOpen(true)
          setTimeout(() => {
            setContent("Copy");
          }, 2000);
        })
        .catch((error) => console.error('Error copying code:', error));
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('click', copyCodeToClipboard);
    }
  }, [ref]);

  return (
    <pre ref={codeRef} className={`!text-lg max-h-[350px] overflow-y-scroll line-numbers bg-transparent !m-0 w-full`}>
      <code
        className={`language-${language}`}
      >{code}</code>
    </pre>
  );
});

export default Snippet;
