import ExternalTooltipHandler from "./ExternalTooltipHandler";

const StackedBarOptions = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: 'index',
  },
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
      border: {
        dash: [5, 5],
        color: "transparent",
      },
      grid: {
        color: "#DCDFE4",
        tickColor: "transparent",
      },
      title: {
        display: true,
        font: {
          family: 'Manrope',
          weight: '500',
          size: 12,
          lineHeight: '14.4px',
        },
        color: '#0C0D10',
        padding: {
          bottom: 16
        },
        text: 'Volume of IPs in each reputation group'
      },
      ticks: {
        stepSize: 25,
        beginAtZero: true,
        callback: function (value) {
          return value + '%';
        },
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        }
      }
    },
    x: {
      beginAtZero: true,
      stacked: true,
      border: {
        color: "#DCDFE4"
      },
      grid: {
        offset: true,
        drawOnChartArea: false,
        tickColor: "#D0D5E0",
        tickLength: 6,
        tickWidth: 1,
      },
      ticks: {
        align: 'center',
        padding: 8,
        callback: function (value) {
          return this.getLabelForValue(value).map((label, index) => index === 1 ? label.substr(0, 3) : label);
        },
        font: {
          family: 'Manrope',
          weight: '400',
          size: 12,
          lineHeight: '14.4px',
        }
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: false,
      position: 'average',
      external: ExternalTooltipHandler,
      itemSort: function (a, b) {
        return a.raw - b.raw;
      },
      callbacks: {
        title: (context) => {
          return context[0].label.replaceAll(',', ' ');
        },
        afterTitle: (context) => {
          return context[0].dataset.afterTitle;
        }
      }
    },
    legend: {
      position: 'bottom',
      reverse: true,
      labels: {
        color: '#4E4E4E',
        font: {
          family: 'Manrope',
          weight: '500',
          size: 14,
          lineHeight: '16.8px',
        },
        padding: 32,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 8,
        boxHeight: 8,
      }
    },
  },
}

export default StackedBarOptions