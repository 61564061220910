import React, { useEffect, useState } from 'react';
import './steps.scss';
import ButtonElement from '../Button/Button';

export const StepsElement = ({ type = 'normal' }) => {
  const [currentStep, setCurrentStep] = useState(-1);
  const data = [
    {
      key: 1,
      title: 'Step text',
      content: 'content 1',
    },
    {
      key: 2,
      title: 'Step text',
      content: 'content 2',
    },
    {
      key: 3,
      title: 'Step text',
      content: 'content 3',
    },
  ];
  const [onPress, setOnPress] = useState(Array(data.length).fill(false));
  const handleStepClick = (action) => {
    if (action === 'prev') {
      if (currentStep === -1) return;
      setCurrentStep((prev) => prev - 1);
    }
    if (action === 'next') {
      if (currentStep === data.length) return;
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleMouseDown = (index) => {
    setOnPress((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseUp = (index) => {
    setOnPress((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };

  useEffect(() => {
    const customSteps = document.querySelectorAll('.custom-steps');
    customSteps.forEach((customStep) => {
      customStep.parentNode.style.backgroundColor = '#363636';
    });
  }, []);

  return (
    <>
      <ButtonElement
        onPress={() => handleStepClick('prev')}
        disabled={currentStep === -1}
        className="mr-[56px]"
        text="Prev"
        size="sm"
      />
      <div className="custom-steps flex items-center gap-5">
        {data?.map(({ title }, index) => {
          return (
            <div
              key={index}
              onClick={() => setCurrentStep(index)}
              onMouseDown={() => handleMouseDown(index)}
              onMouseUp={() => handleMouseUp(index)}
              className={`step-item group flex relative items-center after:content-[''] after:relative after:top-1/2 after:translate-y-[50%] after:rounded-[var(--emt-border-radius-full)] after:border-[length:var(--emt-border-width-200)] after:border-solid after:w-[40px] after:left-0 after:ml-[var(--emt-space-400)] after:z-0 cursor-pointer ${
                index === currentStep
                  ? 'active'
                  : currentStep > index
                  ? 'done'
                  : ''
              }`}
              aria-pressed={onPress[index]}
            >
              <span
                className={`step-number group-hover:bg-[var(--emt-background-surface)] transition relative flex ${
                  type === 'dense'
                    ? 'w-[var(--emt-size-700)] h-[var(--emt-size-700)]'
                    : type === 'normal'
                    ? 'w-[var(--emt-size-800)] h-[var(--emt-size-800)]'
                    : ''
                } flex-col justify-center group-hover:text-[var(--emt-text-primary)] items-center mr-[var(--emt-space-200)] px-3 py-1.5 rounded-[var(--emt-border-radius-400)] border-[length:var(--emt-border-width-100)] border-solid border-[color:var(--emt-border-border-inverse)] text-[color:var(--emt-text-on-bg-fill)] text-[length:var(--emt-font-size-400)] z-10 font-[number:var(--emt-font-weight-bold)]`}
              >
                {currentStep > index ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M13.333 4L5.777 11.556L2 7.778"
                      stroke="#0F5FCC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  index + 1
                )}
              </span>

              <span
                className={`relative step-title text-center text-[color:var(--emt-text-on-bg-fill)] ${
                  type === 'dense'
                    ? 'text-[length:var(--emt-font-size-200)]'
                    : type === 'normal'
                    ? 'text-[length:var(--emt-font-size-300)]'
                    : ''
                } group-hover:text-[color:var(--emt-text-on-bg-fill-transperant-hover)] font-[number:var(--emt-font-weight-medium)] leading-[var(--emt-line-height-120)] transition duration-300 z-10`}
              >
                {title}
              </span>
            </div>
          );
        })}
      </div>
      <ButtonElement
        onPress={() => handleStepClick('next')}
        disabled={currentStep === data.length}
        text="Next"
        size="sm"
      />
    </>
  );
};
