import React from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import StraightLineChartOptions from './StraightLineChartOptions';
import './straight_line_tooltip.scss';

Chart.register(CategoryScale);

const data = {
  labels: [
    '01 February',
    '02 February',
    '03 February',
    '04 February',
    '05 February',
    '06 February',
    '07 February',
    '08 February',
  ],
  datasets: [
    {
      label: 'Inbound TLS rate',
      afterTitle: 'Encrypted traffic',
      backgroundColor: '#105FCB',
      borderColor: '#105FCB',
      borderCapStyle: 'butt',
      borderJoinStyle: 'miter',
      pointBorderColor: '#105FCB',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#105FCB',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 8,
      data: [100, 100, 100, 95, 100, 90, 100, 100, 100, 100, 100],
    },
    {
      label: 'Outbound TLS rate',
      afterTitle: 'Encrypted traffic',
      backgroundColor: '#C62127',
      borderColor: '#C62127',
      borderCapStyle: 'butt',
      borderJoinStyle: 'miter',
      pointBorderColor: '#C62127',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#C62127',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 8,
      data: [100, 100, 100, 100, 100, 100, 100, 100],
    },
  ],
};

const StraightLineChart = () => {
  return (
    <>
      <Line data={data} options={StraightLineChartOptions} />
    </>
  );
};

export default StraightLineChart;
