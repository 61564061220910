import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import InputElement from '../theme/Components/Input/Input'
import { InputSnippet } from '../theme/Components/Input/CodeSnippet'

function InputComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <InputElement />
        }
        title="Normal"
        desc="Input fields are spaces where text can be entered by the user, typically a box with borders and no fill"
        code={InputSnippet.normal.code}
      />
    </div>
  )
}

export default InputComp