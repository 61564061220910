export const ButtonSnippet = {
  primary: [
    {
      key: '1',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' text='Button' variant="primary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='md' text='Button' variant="primary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='sm' text='Button' variant="primary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' fluid text='Button' variant="primary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' disabled text='Button' variant="primary" />
  )
}

export default Element;`.trim()
    },
  ],
  secondary: [
    {
      key: '1',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' text='Button' variant="secondary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='md' text='Button' variant="secondary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='sm' text='Button' variant="secondary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' fluid text='Button' variant="secondary" />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' disabled text='Button' variant="secondary" />
  )
}

export default Element;`.trim()
    },
  ],
  white: [
    {
      key: '1',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' text='Button' variant="white" />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='md' text='Button' variant="white" />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='sm' text='Button' variant="white" />
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' fluid text='Button' variant="white" />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' disabled text='Button' variant="white" />
  )
}

export default Element;`.trim()
    },
  ],
  outline: [
    {
      key: '1',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' text='Button' variant="outline" />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='md' text='Button' variant="outline" />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='sm' text='Button' variant="outline" />
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' fluid text='Button' variant="outline" />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' disabled text='Button' variant="outline" />
  )
}

export default Element;`.trim()
    },
  ],
  ghost: [
    {
      key: '1',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' text='Button' variant="ghost" />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='md' text='Button' variant="ghost" />
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='sm' text='Button' variant="ghost" />
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' fluid text='Button' variant="ghost" />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' disabled text='Button' variant="ghost" />
  )
}

export default Element;`.trim()
    },
  ],
  danger: [
    {
      key: '1',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' text='Button' variant="danger" />
  )

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='md' text='Button' variant="danger" />
  )

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='sm' text='Button' variant="danger" />
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' fluid text='Button' variant="danger" />
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button size='lg' disabled text='Button' variant="danger" />
  )
}

export default Element;`.trim()
    },
  ],
  link: [
    {
      key: '1',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button text='Button' variant="link" />
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import Button from '@email-tracker';

const Element = () => {

  return (
    <Button text='Button' variant="link" disabled />
  )
}

export default Element;`.trim()
    }
  ],
};
