import React from 'react';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import StackedPieChartOptions from './StackedPieChartOptions';
import stackedPiePlugin from './StackedPiePlugin';
import DrawOrderPlugin from './DrawOrderPlugin';

Chart.register(ArcElement, Tooltip, Legend);

const data = {
  labels: [
    'Free plan users',
    'Community plan users',
    'Enterprise plan users',
    'Custom plan users',
    'Startup plan users',
  ],
  datasets: [
    {
      label: 'Series 1',
      data: [18, 0, 0, 0, 0],
      backgroundColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
      borderColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
    },
    {
      label: 'Series 2',
      data: [0, 12, 0, 0, 0],
      backgroundColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
      borderColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
    },
    {
      label: 'Series 3',
      data: [0, 0, 10, 0, 0],
      backgroundColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
      borderColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
    },
    {
      label: 'Series 4',
      data: [0, 0, 0, 3, 0],
      backgroundColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
      borderColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
    },
    {
      label: 'Series 5',
      data: [0, 0, 0, 0, 2],
      backgroundColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
      borderColor: ['#E7EFFA', '#B5CDEF', '#91B5E8', '#5E94DD', '#0F5FCC'],
    },
  ],
};

const StackedPieChart = () => {
  return (
    <div className="relative flex">
      <Pie
        data={data}
        options={StackedPieChartOptions}
        plugins={[stackedPiePlugin, DrawOrderPlugin]}
      />
      <div className="absolute right-[8%]">
        <p className="font-bold text-[14px] leading-[16.8px] pb-2">
          Total users
        </p>
        <p className="font-bold text-[32px] leading-[38.4px]">
          {data.datasets
            .map((dataset) => dataset.data.reduce((a, b) => a + b))
            .reduce((a, b) => a + b) + 'M'}
        </p>
      </div>
    </div>
  );
};

export default StackedPieChart;
