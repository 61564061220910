import React from 'react'
import ButtonElement from './../Button/Button';

const EmptyState = ({ title = "No alert configurations yet.", desc = "Set a new one", action = true }) => {
  return (
    <div className='p-[var(--emt-space-1000)] bg-[var(--emt-background-primary)] border-1 rounded-lg border-[var(--emt-border-primary)] w-full flex flex-col  items-center'>
      <span className="">
        <img src="/images/svg/emptyStateIcon.svg" alt="warning!" />
      </span>
      <div className="flex flex-col items-center mt-[var(--emt-space-600)] gap-[var(--emt-space-200)]">
        <h6 className="text-[var(--emt-text-primary)] font-semibold text-xl">{title}</h6>
        <p className="text-lg font-medium text-[var(--emt-text-tertiary)]">{desc}</p>
      </div>
      {
        action && <ButtonElement className="mt-[var(--emt-space-1000)]" />
      }   </div>
  )
}

export default EmptyState
