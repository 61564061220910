import React, { forwardRef } from 'react';
import { Button, useButton } from '@nextui-org/react';
import { CustomIcon } from '../../icons';

const ButtonElement = forwardRef(({ size = "md", type, onClick, text = 'Button', variant = "primary", fluid, className, disabled, prefix, suffix, ...rest }, ref) => {
    const {
        domRef,
    } = useButton({
        ref
    });
    const BtnSize = size === "lg" ? 'p-[var(--emt-space-button-large)] text-[14px]' : size === 'sm' ? 'p-[var(--emt-space-button-dense)] text-[13px]' : size === 'md' ? 'p-[var(--emt-space-button-normal)] text-[14px]' : "";

    const BtnColor = variant === 'primary' ? 'bg-primary text-[var(--emt-text-button-fill-primary)] hover:bg-[var(--emt-background-button-fill-hovered)] focus:bg-[var(--emt-background-button-fill-pressed)] ' : variant === 'danger' ? "bg-danger text-[var(--emt-text-button-danger-primary)] hover:bg-[var(--emt-background-button-danger-hovered)] focus:bg-[var(--emt-background-button-danger-pressed)]" : variant === "secondary" ? "bg-secondary border border-[var(--emt-border-tertiary)] hover:border-transparent focus:border-transparent text-[var(--emt-text-button-default-primary)] hover:bg-[var(--emt-background-button-default-hovered)] focus:bg-[var(--emt-background-button-default-pressed)]" : variant === "ghost" ? "bg-transparent text-[var(--emt-text-button-ghost-primary)] hover:bg-[var(--emt-background-button-ghost-hovered)] focus:bg-[var(--emt-background-button-ghost-pressed)]" : variant === "outline" ? "bg-transparent border-[var(--emt-background-button-stroke-primary)] text-[var(--emt-text-button-stroke-primary)] hover:border-[var(--emt-background-button-stroke-hovered)] focus:border-[var(--emt-background-button-stroke-pressed)]" : variant === "link" ? "bg-transparent text-[var(--emt-text-button-link-primary)] p-0 underline hover:text-[var(--emt-text-button-link-hovered)] focus:text-[var(--emt-text-button-link-hovered)]" : "bg-[var(--emt-background-button-dark-fill-fill)] text-[var(--emt-text-button-dark-fill-fill)] hover:bg-[var(--emt-background-button-dark-fill-hovered)] focus:bg-[var(--emt-background-button-dark-fill-pressed)]"

    const DisabledClass = disabled && variant === 'primary' ? '!bg-[var(--emt-background-button-fill-disabled)]' : disabled && variant === 'secondary' ? '!bg-[var(--emt-background-button-default-disabled)] !border-transparent !text-[var(--emt-text-button-default-disabled)]' : disabled && variant === 'danger' ? '!bg-[var(--emt-background-button-danger-disabled)]' : disabled && variant === 'ghost' ? '!bg-transparent !text-[var(--emt-text-button-ghost-disabled)]' : disabled && variant === 'link' ? "!text-[var(--emt-text-button-link-disabled)]" : disabled && variant === 'outline' ? '!bg-transparent !border-[var(--emt-background-button-stroke-disabled)] !text-[var(--emt-text-button-stroke-disabled)]' : disabled && variant === "white" ? "!bg-[var(--emt-background-button-dark-fill-disabled)] !text-[var(--emt-text-button-dark-fill-disabled)]" : "";

    return (
        <Button {...rest} type={type} ref={domRef} fullWidth={fluid} disableRipple={variant === "link"} startContent={<span className={''}>{prefix === true ? <CustomIcon /> : prefix}</span>} endContent={<span className={''}>{suffix === true ? <CustomIcon /> : suffix}</span>} isDisabled={disabled} className={`${BtnSize} border border-transparent flex gap-[var(--emt-space-200)] shadow-none font-semibold ${BtnColor} !opacity-100 email-btn min-w-fit !outline-none rounded-md h-auto ${className} ${DisabledClass}  pointer-events-auto disabled:cursor-no-drop`}>
            {text}
        </Button>
    );
})

export default ButtonElement;
