import React from 'react'
import { Input } from "@nextui-org/react";
import { SearchIcon } from '../../icons';
import { Kbd } from "@nextui-org/react";
import './search.scss'
function Search({ label, placeholder = "Search something...", keyboard, transparent, borderLess }) {
  return (
    <Input placeholder={placeholder} startContent={<SearchIcon />} endContent={
      keyboard && <Kbd
        classNames={{
          base: "bg-transparent border border-[var(--emt-border-primary)] rounded-sm px-[var(--emt-space-200)] py-[var(--emt-space-100)] flex gap-[var(--emt-space-100)] shadow-none",
          abbr: "text-[var(--emt-text-placeholder)] text-sm",
          content: "!ml-0 text-[var(--emt-text-placeholder)] text-sm"
        }}
        keys={["command"]}>K</Kbd>
    } type="email" label={label}
      classNames={{
        base: "mail-search",
        inputWrapper: `input-wrapper !bg-[var(--emt-background-secondary)] border-1 border-[var(--emt-border-input)] px-[var(--emt-space-300)] py-[var(--emt-space-250)] h-auto rounded-sm ${transparent ? "!bg-transparent" : ""} ${borderLess ? "border-0" : ""} 
        `,
        innerWrapper: "",
        input: "!px-[var(--emt-space-200)]"
      }}
    />
  )
}

export default Search