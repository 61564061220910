import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import { FormSnippet } from '../theme/Components/Form/CodeSnippet';
import { Form } from '../theme/Components/Form/Form';

function FormValidationComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <Form />
        }
        title="Normal"
        desc="Control for switching between two options"
        code={FormSnippet.normal.code}
      />
    </div>
  )
}

export default FormValidationComp