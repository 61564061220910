export const CardSnippet = {
  large: [
    {
      key: '1',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='lg'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='lg' bg='white'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='lg' bg='dark'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='lg' bg='light>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='lg' bg='stroke'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
  ],
  default: [
    {
      key: '1',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement bg='white'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement bg='dark'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement bg='light>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement bg='stroke'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
  ],
  small: [
    {
      key: '1',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='sm'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '2',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='sm' bg='white'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '3',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='sm' bg='dark'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '4',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='sm' bg='light>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
    {
      key: '5',
      code:
        `
import CardElement from '@email-tracker';

const Element = () => {

  return (
    <CardElement size='sm' bg='stroke'>
      <div className="flex flex-col gap-2">
       <h5 className=" font-bold text-xl">Place holder</h5>
        <p className="text-sm font-normal  underline">How to use placeholders in Figma</p>
      </div>
    </CardElement>
  )
}

export default Element;`.trim()
    },
  ],
};
