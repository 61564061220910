import React from 'react';
import BarChart from '../theme/Components/Graph/BarChart/BarChart';
import StackedBarChart from '../theme/Components/Graph/StackedBarChart/StackedBarChart';
import LineChart from '../theme/Components/Graph/LineChart/LineChart';
import MultiBarChart from '../theme/Components/Graph/MultiBarChart/MultiBarChart';
import CurvedLineChart from '../theme/Components/Graph/CurvedLineChart/CurvedLineChart';
import StraightLineChart from '../theme/Components/Graph/StraightLineChart/StraightLineChart';
import StackedPieChart from '../theme/Components/Graph/StackedPieChart/StackedPieChart';
import CompLayout from '../theme/Layout/CompLayout';
import { GraphCodeSnippet } from '../theme/Components/Graph/CodeSnippet';

const charts = [
  {
    component: <BarChart inputTooltip />,
    title: 'Bar Chart',
    desc: 'Bar chart uses horizontal or vertical rectangles, where the length represents the value of the variable it depicts',
  },
  {
    component: <StackedBarChart />,
    title: 'Stacked Bar Chart',
    desc: 'A variant of the bar chart with multiple groups of data represented within the same bar, with a number of sub divisions',
  },
  {
    component: <LineChart />,
    title: 'line chart',
    desc: 'Line chart depicts the trend of a particular variable over multiple instances, using a number of points connected by a line with a fill in the area under',
  },
  {
    component: <MultiBarChart />,
    title: 'Multi Bar Chart',
    desc: 'A variant of the bar chart where every individual contributer to a particular data set is represented with a bar, and they are placed adjacently for comparision',
  },
  {
    component: <CurvedLineChart />,
    title: 'Curved Line Chart',
    desc: 'A variant of the line chart where the line connected the data points is smoothened to create a continuous curve',
  },
  {
    component: <StraightLineChart />,
    title: 'Straight Line Chart',
    desc: 'A variant of the line chart where the data points are connected using straight lines',
  },
  {
    component: <StackedPieChart />,
    title: 'Stacked Pie Chart',
    desc: 'Pie chart where multiple data sets are represented with circles where each contributes to the whole.',
  },
];

function GraphComp() {
  return (
    <div className="flex flex-col gap-[52px]">
      {charts.map((chart, index) => (
        <CompLayout
          key={index}
          component={<div className="w-full h-full">{chart.component}</div>}
          title={chart.title}
          desc={chart.desc}
          code={GraphCodeSnippet[chart.title]?.code}
        />
      ))}
    </div>
  );
}

export default GraphComp;
