import React from 'react';
import CompLayout from '../theme/Layout/CompLayout';
import TooltipElement from '../theme/Components/Tooltip/Tooltip';
import { TooltipSnippet } from '../theme/Components/Tooltip/CodeSnippet';
import ButtonElement from '../theme/Components/Button/Button';

function TooltipComp() {
  const placements = [
    { title: "Normal", placement: undefined, desc: "Standard tooltip without an arrow, appears directly at the point of interest, used for all icons" },
    { title: "Bottom left arrow", placement: "top-start", arrow: true, desc: "Tooltip with arrow emerging from the bottom left, aligned with the component, used when there is more space towards its top right" },
    { title: "Bottom center arrow", placement: "top", arrow: true, desc: "Tooltip with arrow emerging from the bottom center, used when there is relatively more space above the component" },
    { title: "Bottom right arrow", placement: "top-end", arrow: true, desc: "Tooltip with arrow emerging from the bottom right, aligned with the component, used when there is more space towards its top left" },
    { title: "Top left arrow", placement: "bottom-start", arrow: true, desc: "Tooltip with arrow emerging from the top left, aligned with the component, used when there is more space towards its bottom right" },
    { title: "Top center arrow", placement: "bottom", arrow: true, desc: "Tooltip with arrow emerging from the top center, used when there is relatively more space below the component" },
    { title: "Top right arrow", placement: "bottom-end", arrow: true, desc: "Tooltip with arrow emerging from the top right, aligned with the component, used when there is more space towards its bottom left" },
    { title: "Left top arrow", placement: "right-start", arrow: true, desc: "Tooltip with arrow emerging from the left top, aligned with the component, used when there is more space towards its right bottom" },
    { title: "Left center arrow", placement: "right", arrow: true, desc: "Tooltip with arrow emerging from the left center, suitable for left adjacent items" },
    { title: "Left bottom arrow", placement: "right-end", arrow: true, desc: "Tooltip with arrow emerging from the left bottom, aligned with the component, used when there is more space towards its right top" },
    { title: "Right top arrow", placement: "left-start", arrow: true, desc: "Tooltip with arrow emerging from the right top,  aligned with the component, used when there is more space towards its left bottom" },
    { title: "Right center arrow", placement: "left", arrow: true, desc: "Tooltip with arrow emerging from the right center, suitable for right adjacent items" },
    { title: "Right bottom arrow", placement: "left-end", arrow: true, desc: "Tooltip with arrow emerging from the right bottom, aligned with the component, used when there is more space towards its left top" },
  ];

  return (
    <div className='flex flex-col gap-[52px]'>
      {placements.map(({ title, placement, arrow, desc }) => (
        <CompLayout
          key={title}
          component={<TooltipElement placement={placement} arrow={arrow}>
            <ButtonElement text="Hover Me" />
          </TooltipElement>}
          title={title}
          desc={desc}
          code={TooltipSnippet[title]?.code}
        />
      ))}
    </div>
  );
}

export default TooltipComp;
