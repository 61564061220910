import { CustomIcon, ModalCloseIcon } from '../../icons';

function DrawerElement({ isOpen, setOpen, prefix, children, closeButton = true, size, position = "right" }) {

  const toggleDrawer = () => {
    setOpen(!isOpen);
  };
  const DrawerWidth = size === 'lg' ? "w-[var(--emt-size-14000)]" : "w-[var(--emt-size-10500)]";
  const DrawerHeight = size === 'lg' ? "h-[var(--emt-size-14000)]" : "h-[var(--emt-size-10500)]";
  const DrawerPosition =
    position === "left" ? `left-0 top-0 h-full ${DrawerWidth}` :
      position === "bottom" ? `right-0 bottom-0 w-full ${DrawerHeight}` :
        position === "top" ? `right-0 top-0 w-full ${DrawerHeight}` :
          `right-0 top-0 h-full ${DrawerWidth}`;

  const DrawerAction =
    position === "left" ? (isOpen ? "translate-x-0 top-0" : "-translate-x-[100%] top-0") :
      position === "top" ? (isOpen ? "translate-y-0" : "-translate-y-[100%]") :
        position === "bottom" ? (isOpen ? "translate-y-0" : "-translate-y-[-100%]") :
          (isOpen ? "translate-x-0" : "-translate-x-[-100%]");


  return (
    <div className="relative">
      <button
        className="fixed z-10 inset-0 bg-[var(--emt-background-overlay)] w-full h-full cursor-default"
        onClick={toggleDrawer}
        style={{ display: isOpen ? 'block' : 'none' }}
      ></button>
      <div className={`fixed ${isOpen ? "opacity-1" : "opacity-0"} shadow-[var(--emt-shadow-300)] z-20 ${DrawerPosition} ${DrawerAction} bg-[var(--emt-background-surface)] transform '} transition duration-300 ease-in-out`}>
        <div className="flex justify-between border-b-1 border-[var(--emt-border-primary)] items-center py-[var(--emt-space-300)] px-[var(--emt-space-500)]">
          <div className="flex items-center gap-[var(--emt-space-200)]">
            {
              prefix && <span className=""><CustomIcon stroke='#0C0D10' size={20} /></span>
            }

            <h5 className="text-[var(--emt-text-primary)] font-medium text-sm">Title text</h5>
          </div>
          {
            closeButton && <span className="cursor-pointer rounded-full p-[var(--emt-space-50)] w-[var(--emt-space-600)] h-[var(--emt-space-600)] transition flex justify-center items-center hover:bg-[var(--emt-background-button-default-hovered)]" onClick={() => setOpen(false)}>
              <ModalCloseIcon />
            </span>
          }

        </div>
        <div className="p-[var(--emt-space-500)] h-full">
          {children}
        </div>
      </div>
    </div>
  );
}

export default DrawerElement;
