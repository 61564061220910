import React from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import MultiBarChartOptions from './MultiBarChartOptions';
import './multi_bar_tooltip.scss';

Chart.register(CategoryScale);

const data = {
  labels: [
    '01 February',
    '02 February',
    '03 February',
    '04 February',
    '05 February',
    '06 February',
    '07 February',
    '08 February',
  ],
  datasets: [
    {
      label: 'DKIM',
      afterTitle: 'Authenticated traffic',
      data: [88, 65, 95, 92, 30, 100, 88, 55],
      backgroundColor: '#B5CDEF',
      barPercentage: 0.8,
      categoryPercentage: 0.46,
      borderRadius: {
        topRight: 4,
        topLeft: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      borderSkipped: false,
    },
    {
      label: 'SPF',
      afterTitle: 'Authenticated traffic',
      data: [90, 73, 68, 78, 72, 100, 88, 90],
      backgroundColor: '#5E94DD',
      barPercentage: 0.8,
      categoryPercentage: 0.46,
      borderRadius: {
        topRight: 4,
        topLeft: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      borderSkipped: false,
    },
    {
      label: 'DMARC',
      afterTitle: 'Authenticated traffic',
      data: [100, 38, 95, 77, 88, 100, 88, 48],
      backgroundColor: '#105FCB',
      barPercentage: 0.8,
      categoryPercentage: 0.46,
      borderRadius: {
        topRight: 4,
        topLeft: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      borderSkipped: false,
    },
  ],
};

const MultiBarChart = () => {
  return (
    <>
      <Bar data={data} options={MultiBarChartOptions} />
    </>
  );
};

export default MultiBarChart;
