import React from 'react'
import { Switch } from "@nextui-org/react";
import './toggle.scss'
function ToggleElement() {
  const [isSelected, setIsSelected] = React.useState(false);
  return (
    <Switch isSelected={isSelected} onValueChange={setIsSelected} classNames={{
      base: "mail-toggle",
      wrapper: `mail-toggle-wrapper bg-[var(--emt-background-tertiary)] hover:bg-[var(--emt-background-surface-tertiary)] h-[var(--emt-space-500)] w-[var(--emt-space-1000)] mr-0 px-[var(--emt-space-50)] rounded-full`,
      thumb: "h-[var(--emt-space-400)] w-[var(--emt-space-400)] rounded-full bg-[var(--emt-background-surface)]",
    }} />
  )
}

export default ToggleElement