import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import CheckboxElement from '../theme/Components/Checkbox/Checkbox'
import { CheckboxSnippet } from '../theme/Components/Checkbox/CodeSnippet'

function CheckboxComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <CheckboxElement />
        }
        title="Normal"
        desc="Typical appearance of an unselected checkbox in the product. They allow the user to select multiple choices at the same time"
        code={CheckboxSnippet.normal.code}
      />
      <CompLayout
        component={
          <CheckboxElement>Option</CheckboxElement>
        }
        title="with label"
        desc="Checkboxes that are accompanied by a label to indicate to the user the option they are selecting"
        code={CheckboxSnippet.label.code}
      />
      <CompLayout
        component={
          <CheckboxElement indeterminate />
        }
        title="indeterminate"
        desc="Checkbox state used to indicate that only some, but not all, sub-options are selected. It is often represented with a minus sign"
        code={CheckboxSnippet.indeterminate.code}
      />
    </div>
  )
}

export default CheckboxComp