const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.ip-reputation-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('ip-reputation-tooltip');
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .2s ease';
    tooltipEl.style.minWidth = '118px';
    const tooltipTable = document.createElement('table');
    tooltipTable.classList.add('tooltip-table');

    tooltipEl.appendChild(tooltipTable);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};


const ExternalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      const th = document.createElement('th');
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const colorSpan = document.createElement('span');
      colorSpan.classList.add('color-dot');
      colorSpan.style.backgroundColor = colors.backgroundColor;
      colorSpan.style.borderColor = colors.borderColor;
      colorSpan.style.marginRight = '8px';
      colorSpan.style.display = 'inline-block';

      const valueRegex = /(\d+(?:\.\d+)?)/;
      const labelRegex = /^(.*?):/;
      const value = body.join('').match(valueRegex)[0];
      const label = body.join('').match(labelRegex)[0];
      const tr = document.createElement('tr');
      const td = document.createElement('td');
      const span = document.createElement('span');
      const text = document.createTextNode(label);
      const valueText = document.createTextNode(value + '%');

      td.appendChild(colorSpan);
      span.appendChild(valueText);
      td.appendChild(text);
      td.appendChild(span);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove previous children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const tooltipWidth = tooltipEl.offsetWidth;
  const tooltipHeight = tooltipEl.offsetHeight;
  const tipPositionLeft = 15;

  let posX = positionX + (tooltipWidth / 2) + tooltip.caretX + tipPositionLeft;
  let posY = positionY - (tooltipHeight / 2) + tooltip.caretY;

  if ((posX + tooltipWidth) > window.innerWidth) {
    posX = posX - tooltipWidth - (tipPositionLeft * 2);
    tooltipEl.classList.add('tooltip-right');
  } else {
    tooltipEl.classList.remove('tooltip-right');
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = posX + 'px';
  tooltipEl.style.top = posY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

export default ExternalTooltipHandler;