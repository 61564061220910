export const InputSnippet = {
  normal: {
    key: '1',
    code:
      `import InputElement from '@email-tracker'

const Element = () =>{
return(
  <InputElement />
)

export default Element`
  },
}