import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import ModalElement from '../theme/Components/Modal/Modal'
import ButtonElement from '../theme/Components/Button/Button'
import { ModalSnippet } from '../theme/Components/Modal/CodeSnippet'

function ModalComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <ModalElement heading="Modal Heading">
            <div className="flex items-center flex-col gap-2">
              <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
              <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
            </div>
          </ModalElement>
        }
        title="Normal"
        desc="Modals are used to direct user attention to one particular piece of information or action, by appearing above the existing content in the viewport"
        code={ModalSnippet.normal.code}
      />
      <CompLayout
        component={
          <ModalElement prefix heading="Modal Heading">
            <div className="flex items-center flex-col gap-2">
              <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
              <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
            </div>
          </ModalElement>
        }
        title="with title prefix"
        desc="Modal variant with an icon present before the title for easy identification"
        code={ModalSnippet.prefix.code}
      />
      <CompLayout
        component={
          <ModalElement footer={
            <ButtonElement fluid />
          } heading="Modal Heading">
            <div className="flex items-center flex-col gap-2">
              <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
              <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
            </div>
          </ModalElement>
        }
        title="with custom footer"
        desc="Modal variant that has custom content at the bottom"
        code={ModalSnippet.footer.code}
      />
      <CompLayout
        component={
          <ModalElement heading={null}>
            <div className="flex items-center flex-col gap-2">
              <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
              <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
            </div>
          </ModalElement>
        }
        title="without header"
        desc="Modal variant that doesn't have content at the top"
        code={ModalSnippet.withoutHeader.code}
      />
      <CompLayout
        component={
          <ModalElement footer={false} heading="Modal Heading">
            <div className="flex items-center flex-col gap-2">
              <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
              <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
            </div>
          </ModalElement>
        }
        title="without footer"
        desc="Modal variant that doesn't have content at the bottom"
        code={ModalSnippet.withoutFooter.code}
      />
      <CompLayout
        component={
          <ModalElement closable={false} heading="Modal Heading">
            <div className="flex items-center flex-col gap-2">
              <h5 className="text-[var(--emt-text-primary)] font-bold text-xl">Place holder</h5>
              <p className="text-sm font-normal text-[var(--emt-text-primary)] underline">How to use placeholders in Figma</p>
            </div>
          </ModalElement>
        }
        title="without close icon"
        desc="Variant of modal that doesn't let the user dismiss it without a change in the system conditions"
        code={ModalSnippet.withoutCloseIcon.code}
      />
    </div>
  )
}

export default ModalComp