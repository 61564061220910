export const ChipsSnippet = {
  normal: {
    key: '1',
    code: `
import Chips from '@email-tracker';

const Element = () => {

  return (
    <Chips />
  );
};
    
    export default Element;
`.trim()
  },
};
