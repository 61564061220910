import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import { Alert } from '../../theme/Components/Alert/Alert'
import { AlertSnippet } from '../../theme/Components/Alert/CodeSnippet';

export const Danger = () => {
  const alertConfigurations = [
    { title: "Normal", component: <Alert variant='danger' />, desc: "The default version of the danger alert, which notifies the user of negative outcomes" },
    { title: "with prefix", component: <Alert variant='danger' prefix />, desc: "Variant of a danger alert, it has an icon or label at the start for easy identification" },
    { title: "with Action Buttons", component: <Alert variant='danger' action />, desc: "Variant of a danger alert, it has buttons for the user to take relevant actions based on the danger message" },
    { title: "without close button", component: <Alert variant='danger' closable={false} />, desc: "Variant of a danger alert which cannot be closed manually by the user and goes away after a delay" },
    { title: "with prefix and action", component: <Alert variant='danger' prefix action />, desc: "Variant of a danger alert which has an icon or label in the front for easy identification and buttons to help users take actions" }
  ];

  return (
    <div>
      <h3 className="section-title">danger</h3>
      <p className="comp-desc">
        Type of alert that notifies the user of negative outcomes within the sytem and has a red background.
      </p>
      <div className="flex mt-[32px] gap-[32px] flex-col">
        {alertConfigurations.map(({ title, component, desc }, i) => (
          <CompLayout
            key={i}
            component={component}
            title={title}
            desc={desc}
            code={AlertSnippet.danger[i].code}
          />
        ))}
      </div>
    </div>
  )
}
