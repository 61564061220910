import React, { useRef, useState } from 'react';
import style from './comp_layout.module.scss';
import { CopyIcon } from '../icons';
import Snippet from './Snippet';
import TooltipElement from './../Components/Tooltip/Tooltip';
function CompLayout({ component, title, desc, code }) {
  const copyBtnRef = useRef(null);
  const [content, setContent] = useState("Copy")
  const [isOpen, setOpen] = useState(false)
  return (
    <div className={style['comp-wrapper']}>
      <h6 className={style['comp-title']}>{title}</h6>
      <p className="comp-desc mt-[12px]">{desc}.</p>
      <div className={style['card-wrapper']}>
        <div className={style['comp-container']}>{component}</div>
        {code && (
          <div className={style['code-container']}>
            <Snippet setOpen={setOpen} setContent={setContent} code={code} ref={copyBtnRef} />
            <TooltipElement isOpen={isOpen} setOpen={setOpen} content={content} arrow><span ref={copyBtnRef} className={style['copy-btn']}>
              <CopyIcon />
            </span></TooltipElement>
          </div>
        )}
      </div>
    </div>
  );
}

export default CompLayout;
