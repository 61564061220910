export const ToggleSnippet = {
  normal: {
    key: '1',
    code: `
import ToggleElement from '@email-tracker';

const Element = () => {
  
  return (
    <ToggleElement />
  );
};
    
export default Element;
  `.trim()
  }

}