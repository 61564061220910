import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import TableElement from './../theme/Components/Table/Table';
import { TableSnippet } from '../theme/Components/Table/CodeSnippet';

function TableComp() {
  const tableRow = [
    {
      key: "1",
      name: "Tony Reichert",
      role: "CEO",
      status: "Active",
    },
    {
      key: "2",
      name: "Zoey Lang",
      role: "Technical Lead",
      status: "Paused",
    },
    {
      key: "3",
      name: "Jane Fisher",
      role: "Senior Developer",
      status: "Active",
    },
    {
      key: "4",
      name: "William Howard",
      role: "Community Manager",
      status: "Vacation",
    },
  ];
  const tableColumns = [
    {
      key: "name",
      label: "NAME",
    },
    {
      key: "role",
      label: "ROLE",
    },
    {
      key: "status",
      label: "STATUS",
    },
  ];
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <TableElement rows={tableRow} columns={tableColumns} />
        }
        title="Normal"
        desc="A table is an organized display of data in rows and columns for easy comprehension and comparison"
        code={TableSnippet.normal.code}
      />
      <CompLayout
        component={
          <TableElement pagination rows={tableRow} columns={tableColumns} />
        }
        title="with Pagination"
        desc="A table with pagination controls for navigating through multiple pages of content"
        code={TableSnippet.pagination.code}
      />
      <CompLayout
        component={
          <TableElement multiple rows={tableRow} columns={tableColumns} />
        }
        title="with checkbox"
        desc="A table with checkboxes to allow for selection of rows to take bulk actions"
        code={TableSnippet.checkbox.code}
      />
      <CompLayout
        component={
          <TableElement pagination multiple rows={tableRow} columns={tableColumns} />
        }
        title="with Checkbox and Pagination"
        desc="A table with pagination controls and checkboxes, used when there are multiple pages of content, and selection of rows is required to take bulk actions"
        code={TableSnippet.checkboxAndPagination.code}
      />
    </div>
  )
}

export default TableComp