import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import RadioElement from '../theme/Components/Radio/Radio'
import { RadioSnippet } from '../theme/Components/Radio/CodeSnippet'

function RadioComp() {
  const radioData = [
    {
      value: 'buenos-aires',
      label: 'Buenos Aires'
    }, {
      value: 'bogota',
      label: 'Bogota'
    },
    {
      value: 'bogota-2',
      label: 'Bogota-2',
      disabled: true
    }
  ]
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <RadioElement radioData={radioData} />
        }
        title="Normal"
        desc="Radio button that typically appears in in the design, in its unselected state. They allow the user to select only one option at a time"
        code={RadioSnippet.normal.code}
      />
    </div>
  )
}

export default RadioComp