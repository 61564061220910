import React from 'react'
import CompLayout from '../theme/Layout/CompLayout'
import { Navbar } from '../theme/Components/Navbar/Navbar'
import { NavbarSnippet } from '../theme/Components/Navbar/CodeSnippet'


function NavbarComp() {
  return (
    <div className='flex flex-col gap-[52px]'>
      <CompLayout
        component={
          <Navbar organization />
        }
        title="normal"
        desc="A horizontal container that appears at the top of the system and with high level options for the user to choose from to change the view of the system"
        code={NavbarSnippet.organization.code}
      />
      {/* <CompLayout
        component={
          <Navbar settings />
        }
        title="super admin navbar"
        desc="A vertical container that appears at the side of the system and with high level options for the user to choose from to change the view of the system"
        code={NavbarSnippet.settings.code}
      /> */}
    </div>
  )
}

export default NavbarComp