import React from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import lineChartOptions from './LineChartOptions';
import './line_tooltip.scss';
import HoverLine from './HoverLine';

Chart.register(CategoryScale);

const data = {
  labels: [
    ['01', 'February'],
    ['02', 'February'],
    ['03', 'February'],
    ['04', 'February'],
    ['05', 'February'],
    ['06', 'February'],
    ['07', 'February'],
    ['08', 'February'],
    ['09', 'February'],
  ],
  datasets: [
    {
      label: 'Line Plot',
      fill: true,
      backgroundColor: (context) => {
        if (!context.chart.chartArea) {
          return;
        }
        const {
          ctx,
          chartArea: { top, bottom },
        } = context.chart;
        const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
        gradientBg.addColorStop(0, 'rgba(15, 95, 204, 0.25)');
        gradientBg.addColorStop(1, 'rgba(15, 95, 204, 0)');
        return gradientBg;
      },
      borderColor: '#0F5FCC',
      borderCapStyle: 'butt',
      borderJoinStyle: 'miter',
      pointBorderColor: '#0F5FCC',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#0F5FCC',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 8,
      data: [25, 0, 0, 50, 50, 75, 25, 50, 50, 50],
    },
  ],
};

const LineChart = () => {
  return (
    <>
      <Line data={data} options={lineChartOptions} plugins={[HoverLine]} />
    </>
  );
};

export default LineChart;
