export const TagSnippet = {
  normal: {
    key: '1',
    code:
      `
import TagElement from '@email-tracker';

const Element = () => {

  return (
    <TagElement>Chip comp</TagElement>
  )
}

export default Element;`.trim()
  },
  closable: {
    key: '1',
    code:
      `
import TagElement from '@email-tracker';

const Element = () => {

  return (
    <TagElement closable>Chip comp</TagElement>
  )
}

export default Element;`.trim()
  },
}