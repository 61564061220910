import { Avatar, AvatarGroup } from '@nextui-org/react'
import React from 'react'

function AvatarGroupElement({ data }) {
  return (
    <AvatarGroup
      classNames={{
      }}
      renderCount={(count) => <Avatar classNames={{
        base: "rounded-full w-[var(--emt-space-1000)] h-[var(--emt-space-1000)] bg-[var(--emt-background-secondary)]",
        name: "text-[var(--emt-text-primary)] text-base font-medium"
      }} name={`+${count}`} />}
    >
      {
        data.map((item) => {
          return (
            <Avatar key={item.key} classNames={{
              base: "rounded-full w-[var(--emt-space-1000)] h-[var(--emt-space-1000)] border-2 border-[var(--emt-background-surface)]"
            }} src={item.src} />
          )
        })
      }
    </AvatarGroup >
  )
}

export default AvatarGroupElement